import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Link} from 'react-router-dom';
import {OverlayContext} from './../../contexts/OverlayContext';
import styled from 'styled-components';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable, OzButton, OzTag} from '@ozwol/webui';

import PlansService from '../../services/PlansService';

import InsiderPlansModalNew from './InsiderPlansModalNew';

import Filters from '../../components/Filters';
import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import NoResult from '../../components/NoResult';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, convertNumber} from './../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2){
    word-break: break-all;
    white-space: unset;
  }
`;

const InsiderPlansList = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);

  const [list, setList] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  const [fetchError, setFetchError] = useState(false);

  const DEFAULT_FILTERS = {
    "search": "",
    "active": true,
    "plan_type": null,
    "is_custom": null,
    "credit_roll_over": null
  };
  const [filters, setFilters] = useState(
    localStorage.getItem("ozwolConsoleFilterInsiderPlans"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? 
      JSON.parse(localStorage.getItem("ozwolConsoleFilterInsiderPlans"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))))
    : 
      DEFAULT_FILTERS
    );

  const changeFilters = useCallback((name, value) => {
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const getList = useCallback(() => {
    PlansService.adminList({
      "search": filters.search.length > 2 ? filters.search : "",
      "active": filters.active,
      "plan_type": filters.plan_type,
      "is_custom": filters.is_custom,
      "credit_roll_over": filters.credit_roll_over
    }).then((response) => {
      localStorage.setItem("ozwolConsoleFilterInsiderPlans"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(filters));
      setList(response.result);
      setNextPage(response.nextPage);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [filters]); //page, filters

  const loadMore = useCallback(() => {
    PlansService.adminList({
      "search": filters.search.length > 2 ? filters.search : "",
      "active": filters.active,
      "plan_type": filters.plan_type,
      "is_custom": filters.is_custom,
      "credit_roll_over": filters.credit_roll_over,
      "page": nextPage
    }).then((response) => {
      setList([...list, ...response.result]);
      setNextPage(response.nextPage);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [list, nextPage, filters]);

  useEffect(() => {
    getList();
  }, [getList, filters]); 


  return (
    <OzContainer size="extra">
      <Meta title={"Plans - Insider"} />
      <PageHeader
        breadcrumbsIcon="amp_stories"
        breadcrumbsText={["Plans"]}
        refScrollerPage={refScrollerPage}
        buttons={[
          {
            "icon": "add",
            "text": "Add",
            "onClick": () => addOverlay({
              reference: "form",
              content: <InsiderPlansModalNew />
            })
          }
        ]}
      />

      <OzRow>
        <OzCol>
          <HealthyWrapper error={fetchError} loading={!list}>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"Plans"}
                />
              }
              headerSeparator={true}
            >
              <Filters
                onChange={(...args) => changeFilters(...args)}
                fields={
                  [
                    {
                      "label": "Search",
                      "name": "search",
                      "size": 4,
                      "type": "text",
                      "placeholder": null,
                      "value": filters.search
                    },{
                      "label": "Status",
                      "name": "active",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.active,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },
                        {
                          "name": "Enabled",
                          "value": true
                        },{
                          "name": "Disabled",
                          "value": false
                        }
                      ]
                    },{
                      "label": "Type",
                      "name": "plan_type",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.plan_type,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },{
                          "name": "Paid",
                          "value": "PAID"
                        },
                        {
                          "name": "Complimentary",
                          "value": "COMPLIMENTARY"
                        }
                      ]
                    },{
                      "label": "Custom",
                      "name": "is_custom",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.is_custom,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },{
                          "name": "Official",
                          "value": false
                        },
                        {
                          "name": "Custom",
                          "value": true
                        }
                      ]
                    },{
                      "label": "Rollover",
                      "name": "credit_roll_over",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.credit_roll_over,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },
                        {
                          "name": "Yes",
                          "value": true
                        },{
                          "name": "No",
                          "value": false
                        }
                      ]
                    },{
                      "size": 2,
                      "type": "clear",
                      "callback": () => {
                        setFilters(DEFAULT_FILTERS);
                        // localStorage.removeItem("ozwolConsoleFilterInsiderPlans"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))
                      }
                    }
                  ]
                }
              />
              <hr/>

            
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "Name",
                        "Credits",
                        "Price",
                        "Type",
                        "Duration",
                        "Custom",
                        "Rollover",
                        "Team"
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          values: [
                            item.name,
                            convertNumber(item.credits),
                            "€ " + item.price,
                            <OzTag variant={item.planType === "COMPLIMENTARY" ? "bittersweet" : "silvertree"}>{item.planType === "COMPLIMENTARY" ? "Comp." : "Paid"}</OzTag>,
                            <OzTag variant={item.periodDuration === "MONTHLY" ? "silvertree" : item.periodDuration === "ANNUALLY" || item.periodDuration === "SEMIANNUALLY" ? "chetwodeblue" : "bittersweet"}>{item.periodDuration === "MONTHLY" ? "Monthly" : item.periodDuration === "ANNUALLY" ? "Annually" : item.periodDuration === "SEMIANNUALLY" ? "Semiann." : "Infinite"}</OzTag>,
                            <OzTag variant={item.isCustom ? "chetwodeblue" : "silvertree"}>{item.isCustom ? "Custom" : "Official"}</OzTag>,
                            item.planType === "COMPLIMENTARY" ? null : <OzTag variant={item.creditRollOver ? "silvertree" : "bittersweet"}>{item.creditRollOver ? "Yes" : "No"}</OzTag>,
                            <OzTag variant={item.teamAllowed ? "silvertree" : "bittersweet"}>{item.teamAllowed ? "Yes" : "No"}</OzTag>
                          ],
                          link: ({children}) => (<Link to={"/insider/plans/"+item.uuid}>{children}</Link>)
                        }))
                      }
                    />
                  </TableWrapper>
                  {nextPage ? 
                    <>
                      <br/><br/>
                      <center>
                        <OzButton variant="push-black" onClick={() => loadMore()}>Load more...</OzButton>
                      </center>
                    </>
                  : null}
                </>
              : <NoResult />}           
            </OzCard>          
          </HealthyWrapper>    
        </OzCol>
      </OzRow>
    </OzContainer>
  )
}

export default InsiderPlansList;
