import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from './../../contexts/OverlayContext';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {BITTERSWEET, OzTable, OzProjectTag, OzContainer, OzRow, OzCol, OzCard, OzButton, OzInput, OzTag, OzSelect, OzCheckbox} from '@ozwol/webui';

import ApiKeysService from '../../services/ApiKeysService';
import UsersService from '../../services/UsersService';
import PlansService from './../../services/PlansService';
import ProjectsService from './../../services/ProjectsService';

import InsiderUsersModalNew from './InsiderUsersModalNew';
import InsiderUsersSingleApiKeysModalNew from './InsiderUsersSingleApiKeysModalNew';
import InsiderUsersSingleApiKeysModalEdit from './InsiderUsersSingleApiKeysModalEdit';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import ModalSuccess from '../../components/ModalSuccess';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import NoResult from '../../components/NoResult';
import PromptedButton from '../../components/PromptedButton';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, testValidField, COUNTRIES} from './../../helpers';


const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;
const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: 300;  
`;

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(1),
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
`;

const InsiderUsersSingle = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [search, setSearch] = useState(null);
  const {uuid} = useParams();
  const [item, setItem] = useState();
  const [originalItem, setOriginalItem] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessagePlan, setErrorMessagePlan] = useState(false);

  const [fetchError, setFetchError] = useState(false);

  const [listApiKeys, setListApiKeys] = useState(null);
  const [listProjects, setListProjects] = useState(null);

  const getApiKeyList = useCallback(() => {
    ApiKeysService.adminList({"userUuid": uuid}).then((response) => {
      setListApiKeys(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [uuid]);

  useEffect(() => {
    PlansService.adminList({
      "plan_type": "PAID",
      "search": search && search.length > 2 ? search : "",
    }).then((response) => {
      if(response && response.result){
        setPlans(response.result);
      }
    });
  }, [search]);

  const getItem = useCallback(() => {
    UsersService.adminGet(uuid).then((response) => {
      setItem(response);
      setOriginalItem(response);
      getApiKeyList();
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [uuid, getApiKeyList]);

  const toggleActiveItem = useCallback(() => {
    UsersService.adminUpdate(item.uuid, {active: !item.active}).then((response) => {
      getItem();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item, getItem]);

  const deleteItem = useCallback(() => {
    UsersService.adminDelete(item.uuid).then((response) => {
      window.location.replace("/insider/users");
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item]);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    let salvageableItem = {...item};
    if(salvageableItem.emailPec === ""){
      salvageableItem.emailPec = null;
    }
    UsersService.adminUpdate(uuid, salvageableItem).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [uuid, item, addOverlay]);

  const selectCandidatePlan = useCallback((selectedPlan) => {
    setErrorMessagePlan("");
    UsersService.adminUpdate(uuid, {
      candidatePlan: selectedPlan
    }).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setSelectedPlan(null);
      getItem();
    }).catch((error) => {
      setErrorMessagePlan(error);
    });
  }, [addOverlay, uuid, getItem]);

  const requestChangePlan = useCallback((selectedPlan) => {
    setErrorMessagePlan("");
    UsersService.adminChangePlan(uuid, selectedPlan ? selectedPlan.uuid : null).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setSelectedPlan(null);
      getItem();
    }).catch((error) => {
      setErrorMessagePlan(error);
    });
  }, [uuid, addOverlay, getItem]);

  useEffect(() => {
    getItem();
    ProjectsService.adminList({
      "userUuid": uuid
    }).then((response) => {
      setListProjects(response);
    });
  }, [uuid, getItem]);


  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.givenName, true)){
        valid = false;
      }
      if(!testValidField(item.familyName, true)){
        valid = false;
      }
      if(!testValidField(item.company, true)){
        valid = false;
      }
      if(!testValidField(item.email, true)){
        valid = false;
      }
      // if(!testValidField(item.address, true)){
      //   valid = false;
      // }
      // if(!testValidField(item.city, true)){
      //   valid = false;
      // }
      // if(!testValidField(item.zip, true)){
      //   valid = false;
      // }
      // if(!testValidField(item.countryId, true)){
      //   valid = false;
      // }
      // if(!testValidField(item.vatNumber, true)){
      //   valid = false;
      // }
      // if(!testValidField(item.sdiNumber, true)){
      //   valid = false;
      // }
      // if(!testValidField(item.emailPec, true)){
      //   valid = false;
      // }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem]);

  const deleteApiKey = useCallback((item) => {
    getApiKeyList(null);
    ApiKeysService.adminDelete(item.uuid).then((response) => {
      getApiKeyList();
    }).catch((error) => {
      getApiKeyList(null);
      setFetchError(formatError(error));
    });
  }, [getApiKeyList]);
  
  return (
    <OzContainer size="extra">
      <Meta title={(item && item.givenName && item.familyName ? item.givenName + " " + item.familyName : "") + " - Users - Insider"} />
      <PageHeader
        breadcrumbsIcon="person"
        breadcrumbsText={[
          <>{item && item.givenName && item.familyName ? item.givenName + " " + item.familyName : ""} {item && !item.active ? <OzTag variant="bittersweet" size="small">DISABLED</OzTag> : null }</>
        ]}
        refScrollerPage={refScrollerPage}
        back={"/insider/users"}
        buttons={[
          {
            "icon": "add",
            "text": "Add",
            "onClick": () => addOverlay({
              reference: "form",
              content: <InsiderUsersModalNew />
            })
          },
          {
            "icon": item && !item.active ? "visibility" : "visibility_off",
            "text": item && !item.active ? "Enable" : "Disable",
            "onClick": () => toggleActiveItem()
          },
          {
            "icon": "delete",
            "text": "Delete",
            "prompt": "DELETE",
            "onClick": () => deleteItem()
          }
        ]}
      />

      <HealthyWrapper error={fetchError} loading={!item}>
        {item ?
          <OzRow>
            <OzCol>
              <OzCard
                footerLeft={<Link to={"/insider/users"}><OzButton variant={"chetwodeblue-o"}>Back</OzButton></Link>}
                footerRight={<OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
              >
                <OzRow>
                  <OzCol withmd="12">
                    <CardHeaderTitle first title="Personal info" />
                  </OzCol>
                </OzRow>
                <OzRow>
                  <OzCol widthmd="12">
                    <OzInput
                      label="UUID"
                      width="100%"
                      value={item.uuid}
                      placeholder="UUID"
                      copyable={true}
                      disabled
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Given name"
                      width="100%"
                      value={item && item.givenName ? item.givenName : ""}
                      highlighted={item && originalItem && item.givenName !== originalItem.givenName}
                      placeholder="Given name"
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.givenName = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Family name"
                      width="100%"
                      value={item && item.familyName ? item.familyName : ""}
                      highlighted={item && originalItem && item.familyName !== originalItem.familyName}
                      placeholder="Family name"
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.familyName = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Account email"
                      width="100%"
                      value={item && item.email ? item.email : ""}
                      highlighted={item && originalItem && item.email !== originalItem.email}
                      placeholder="Account email"
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.email = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Phone"
                      width="100%"
                      value={item && item.phoneNumber ? item.phoneNumber : ""}
                      highlighted={item && originalItem && item.phoneNumber !== originalItem.phoneNumber}
                      placeholder="Phone"
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.phoneNumber = val;
                        setItem(tempItem);
                      }}
                    />
                  </OzCol>
                </OzRow>
                <OzRow>
                  <OzCol withmd="12">
                    <CardHeaderTitle first={false} title="Billing info" />
                  </OzCol>
                </OzRow>
                <OzRow>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Company"
                      width="100%"
                      value={item && item.company ? item.company : ""}
                      highlighted={item && originalItem && item.company !== originalItem.company}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.company = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Address"
                      width="100%"
                      value={item && item.address ? item.address : ""}
                      highlighted={item && originalItem && item.address !== originalItem.address}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.address = val;
                        setItem(tempItem);
                      }}
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="City"
                      width="100%"
                      value={item && item.city ? item.city : ""}
                      highlighted={item && originalItem && item.city !== originalItem.city}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.city = val;
                        setItem(tempItem);
                      }}
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="ZIP"
                      width="100%"
                      value={item && item.zip ? item.zip : ""}
                      highlighted={item && originalItem && item.zip !== originalItem.zip}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.zip = val;
                        setItem(tempItem);
                      }}
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="State/Province"
                      width="100%"
                      value={item && item.state ? item.state : ""}
                      highlighted={item && originalItem && item.state !== originalItem.state}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.state = val ? val.slice(0,2) : "";
                        setItem(tempItem);
                      }}
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzSelect
                      label="Country"
                      variant={"default"}
                      width={"100%"}
                      maxHeight={"300px"}
                      selected={item && item.countryId ? item.countryId.toUpperCase() : ""}
                      highlighted={item && originalItem && item.countryId !== originalItem.countryId}
                      placeholder={"Select project"}
                      values={COUNTRIES.map((item) => ({
                        "name": item.en,
                        "value": item.alpha2.toUpperCase()
                      }))}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.countryId = val;
                        setItem(tempItem);
                      }}
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Vat Number"
                      width="100%"
                      value={item && item.vatNumber ? item.vatNumber : ""}
                      highlighted={item && originalItem && item.vatNumber !== originalItem.vatNumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.vatNumber = val;
                        setItem(tempItem);
                      }}
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="SDI"
                      width="100%"
                      value={item && item.sdiNumber ? item.sdiNumber : ""}
                      highlighted={item && originalItem && item.sdiNumber !== originalItem.sdiNumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.sdiNumber = val;
                        setItem(tempItem);
                      }}
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="PEC"
                      width="100%"
                      value={item && item.emailPec ? item.emailPec : ""}
                      highlighted={item && originalItem && item.emailPec !== originalItem.emailPec}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.emailPec = val;
                        setItem(tempItem);
                      }}
                      // required
                    />
                  </OzCol>
                </OzRow>
                <OzRow>
                  <OzCol withmd="12">
                    <CardHeaderTitle first={false} title="Payment settings" />
                  </OzCol>
                </OzRow>
                <OzRow>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Stripe Customer ID"
                      width="100%"
                      value={item && item.paymentGatewayInfo && item.paymentGatewayInfo.stripe_customer_id ? item.paymentGatewayInfo.stripe_customer_id : ""}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.emailPec = val;
                        setItem(tempItem);
                      }}
                      disabled
                      copyable
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="FIC ID"
                      width="100%"
                      value={item && item.ficId ? item.ficId : ""}
                      disabled
                      copyable
                      // required
                    />
                  </OzCol>
                  <OzCol widthmd="3">
                    <OzCheckbox
                      label="First deferred payment allowed"
                      labelSpacing
                      selected={item && item.firstDeferredPaymentAllowed}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.firstDeferredPaymentAllowed = val;
                        setItem(tempItem);
                      }}
                    />
                  </OzCol>
                  <OzCol widthmd="3">
                    <OzCheckbox
                      label="Plan changeable"
                      labelSpacing
                      selected={item && item.planChangeable}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.planChangeable = val;
                        setItem(tempItem);
                      }}
                    />
                  </OzCol>
                </OzRow>
                <OzRow>
                  <OzCol>
                    <ErrorMessage>
                      {errorMessage}
                    </ErrorMessage>
                  </OzCol>
                </OzRow>
              </OzCard>
            </OzCol>
            <OzCol>
              <OzCard
                footerLeft={<Link to={"/insider/users"}><OzButton variant={"chetwodeblue-o"}>Back</OzButton></Link>}
                footerRight={
                  item.currentPlan.planType !== "PAID" ? 
                    <OzButton onClick={() => selectCandidatePlan(selectedPlan)} variant={"chetwodeblue"}>Save</OzButton>
                  :
                    <OzButton onClick={() => requestChangePlan(selectedPlan)} variant={"chetwodeblue"}>Save</OzButton>
                }
              >
                <OzRow>
                  <OzCol withmd="12">
                    <CardHeaderTitle first title="Plan status" />
                  </OzCol>
                </OzRow>
                <OzRow>
                  <OzCol widthmd="3">
                    <Label>Current plan</Label>
                    {item.currentPlan ? <OzTag variant={item.currentPlan.isCustom ? "chetwodeblue" : "silvertree"}>{item.currentPlan.name}</OzTag> : <OzTag variant="bittersweet">NO PLAN</OzTag>}
                  </OzCol>
                  {
                    item.currentPlan.planType === "PAID" ? 
                      <OzCol widthmd="3">
                        <Label>Next plan</Label>
                        {item.nextPlan ? <OzTag variant={item.nextPlan.isCustom ? "chetwodeblue" : "silvertree"}>{item.nextPlan.name}</OzTag> : <OzTag variant="bittersweet">NO PLAN</OzTag>}
                      </OzCol>
                    : item.candidatePlan ? 
                      <OzCol widthmd="3">
                        <Label>Candidate plan</Label>
                        <OzTag variant={item.candidatePlan.isCustom ? "chetwodeblue" : "silvertree"}>{item.candidatePlan.name}</OzTag>
                      </OzCol>
                    : null
                  } 
                  <OzCol widthmd="6">
                  
                    <OzSelect
                      variant={"default"}
                      width={"215px"}
                      height={"35px"}
                      maxHeight={"200px"}
                      selected={selectedPlan && selectedPlan.uuid ? selectedPlan.uuid : null }
                      highlighted={selectedPlan}
                      label={item.currentPlan.planType === "PAID" ? "Force plan change request" : "Select candidate plan"}
                      values={[{
                        "name": <i>None</i>,
                        "value": null,
                      }].concat(plans.map((item) => ({
                        "name": <OzTag variant={item.isCustom ? "chetwodeblue" : "silvertree"}>{item.name}</OzTag>,
                        "value": item.uuid
                      })))}
                      searchable={true}
                      onChange={(val) => setSelectedPlan(plans.filter(plan => plan.uuid === val)[0])}
                      onSearch={(val) => setSearch(val)}
                    />
                  </OzCol>
                  <OzCol>
                    <ErrorMessage>
                      {errorMessagePlan}<br/>
                    </ErrorMessage>
                  </OzCol>

                </OzRow>
              </OzCard>
            </OzCol>
            
            <OzCol>
              <OzCard
                headerLeft={
                  <CardHeaderTitle 
                    title={"API Keys"}
                  />
                }
                headerSeparator={true}
                footerRight={<OzButton onClick={() => addOverlay({
                  reference: "form",
                  content: <InsiderUsersSingleApiKeysModalNew userUuid={uuid} projects={listProjects} onSuccess={() => getItem()} />
                })} variant={"chetwodeblue"}>Add</OzButton>}
              >
                {listApiKeys && listApiKeys.length > 0 ?
                  <>
                    <TableWrapper>
                      <OzTable
                        columns={[
                          "Description",
                          "Key",
                          "Active",
                          "Projects",
                          ""
                        ]}
                        values={
                          listApiKeys.map(item => ({
                            style: !item.active ? {
                              background: BITTERSWEET+"33",
                              backgroundHover: BITTERSWEET+"55"
                            } : {},
                            values: [
                              item.description,
                              <OzInput width="100%" copyable type="password" toggleVisibility disabled value={item.key} />,                              
                              <OzTag variant={item.active ? "silvertree" : "bittersweet"}>{item.active ? "Yes" : "No"}</OzTag>,
                              item && item.projectsUuid && item.projectsUuid.length > 0 ? 
                                (item.projectsUuid[0] === "*" ? 
                                  "All" 
                                : 
                                  item.projectsUuid.map(projectUuid => 
                                    listProjects && listProjects.filter(val => val.uuid === projectUuid).length > 0 ?
                                      listProjects.filter(val => val.uuid === projectUuid).slice(0,1).map(project =>
                                        <OzProjectTag
                                          direction="horizontal"
                                          key={project.uuid}
                                          name={project.name}
                                          uuid={project.uuid}
                                          color={project.color}
                                        />
                                      )
                                    : "-"
                                  )
                                ) 
                              : "-",
                              <>
                                <OzButton onClick={() => addOverlay({
                                  reference: "form",
                                  content: <InsiderUsersSingleApiKeysModalEdit uuid={item.uuid} projects={listProjects} onSuccess={() => getApiKeyList()} />
                                })} noMinWidth={true} variant="push-black" size="small"><span className="material-symbols-outlined">edit</span></OzButton>
                                &nbsp;
                                <PromptedButton 
                                  buttonVariant="push-bittersweet" 
                                  buttonSize={"small"} 
                                  buttonText={<span className="material-symbols-outlined">delete</span>} 
                                  prompt="DELETE"
                                  buttonNoMinWidth={true}
                                  onConfirm={() => deleteApiKey(item)} 
                                /> 
                                
                              </>
                            ]
                          }))
                        }
                      />
                    </TableWrapper>
                  </>
                : <NoResult />}   


                <ErrorMessage>{errorMessage}</ErrorMessage>
              </OzCard>
            </OzCol>
          </OzRow>
        : null }
      </HealthyWrapper>
    </OzContainer>
  )
}

export default InsiderUsersSingle;
