import React, {useState, useEffect, useRef} from 'react';
// import React, {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import styled from 'styled-components';

import {BITTERSWEET, CHETWODEBLUE, SILVERTREE, LILAC, OzButton, OzCopyable, OzTooltip, OzTable, OzContainer, OzRow, OzCol, OzCard, OzIcon, OzChartPie, OzChartCalendar, OzChartLine, OzChartHistogram} from '@ozwol/webui';

import AccountService from './../../services/AccountService';
// import UsersService from './../../services/UsersService';
import ProjectsService from './../../services/ProjectsService';
import LogsService from './../../services/LogsService';

// import ReferencePeriodTimeline from './../../chunks/ReferencePeriodTimeline';
// import LogToggle from './../../components/LogToggle';
import Meta from './../../components/Meta'; 
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import MegaTooltip from '../../components/MegaTooltip';

import {changeDate, checkPermission, getArrayFromInterval, formatError, convertNumber, convertSize, getFormattedMonth, getFormattedDate, getFormattedTime, getWeekDay, isSameDate, usageToGraph} from './../../helpers';

const Detail = styled.div`
  text-transform: uppercase;
  color: ${CHETWODEBLUE};
  
  font-size: 12px;
`;

const CardPhraseCollapsed = styled.div`
  min-width: 160px;
  text-align: right;
  display: flex;
  justify-content: end;
  height: 100%;
`;

const CardPhrase = styled.div`

  display: flex;
  align-items: center;
  height: 100%;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;
  position: relative;
  top: 2px;

  display: flex;
  // align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
  position: relative;
  top: -2px;
`;

const MegaBadgeCnt = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const MegaBadge = styled.div`
  background: #ffffff;
  border: 2px solid ${props => props.variant === "today" ? SILVERTREE+"60" : LILAC};
  color: #303030;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 15px 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
`;


const ChartModeContainer = styled.div`
  display: flex;
`;
const ChartModeButton = styled.div`
  background-color: ${props => props.active ? CHETWODEBLUE : CHETWODEBLUE+"12"};
  color: ${props => props.active ? "#ffffff" : "#303030"};
  padding: 1px 7px;
  border-radius: 3px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const NoLog = styled.div`
  text-align: center;
  padding: 50px;
  font-size: 12px;
  font-weight: 400;
`;

const ChartContent = styled.div`
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0, -50%);
  text-align: center;
  width: 90%;
  padding: 0 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;
const ChartUsed = styled.div`
  color: #303030;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
`;
const ChartUsedOf = styled.div`
  font-size: 12px;
`;
// const ChartTotal = styled.div`
//   color: #30303099;
//   font-size: 12px;
//   font-weight: 400;
// `;

const LogTableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(1),
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
`;
const LogCost = styled.div`
  text-align: center;
`;
const LogExecution = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  color: ${props => props.status === "ERROR" ? "#FF4848" : props.status === "WARNING" ? "#FFB100" : "#4AD384"};
  margin: 0 auto;
`;

const Dark = styled.div`;
  font-size: 12px;
`;
const Light = styled.div`
  color: #30303099;
  font-size: 12px;
`;
const Accordion = styled.div`
  display: flex;
  font-size: 12px;

  & table{
    width: 100%;
  }
  & table,
  & table *{
    border: 0 !important;
    background: transparent !important;
  }
`;
const AccordionLeft = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: ${props => props.full ? "100%" : "60%"};

  & *{
    white-space: normal;
  }
  & b{
    white-space: nowrap;
  }
`;
const AccordionRight = styled.div`
  color: ${BITTERSWEET};
  flex-grow: 0;
  flex-shrink: 0;
  width: 40%;
`;
const LogRow = styled.div`
  display: flex;
  padding-bottom: 3px;
  padding-left: 20px;
`;
const LogKey = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 120px;
  white-space: nowrap;
  font-weight: 600;
`;
const LogValue = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  white-space: normal; 
  width: calc(100% - 120px);
`;

const AssetsCnt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const AssetsBlock = styled.div`
  margin-bottom: 50px;
  & > *{
    margin-top: -10px;
    margin-bottom: -10px;
  }
`;

const Calendar = styled.div`
  width: 100%;
`;
const ScrollRef = styled.div`
  scroll-margin: 70px;
`;
const ChartXLabel = styled.div`
  line-height: 14px;
  display: flex;
  justify-content: center;
`;

// const CreditsDashboard = styled.div`
//   display: flex;
//   flex-direction: row;
//   height: 100%;
// `;
// const CreditsDashboardLeft = styled.div`
//   flex-grow: 1;
//   flex-shrink: 1;
//   width: calc(100% - 185px);
//   & > * {
//     width: 100%;
//     border-top-right-radius: 0px;
//     border-bottom-right-radius: 0px;    
//   }
// `;
// const CreditsDashboardRight = styled.div`
//   flex-grow: 0;
//   flex-shrink: 0;
//   width: 185px;
//   & > * {
//     width: 100%;
//     padding: 0px;
//     border-top-left-radius: 0px;
//     border-bottom-left-radius: 0px;   
//     border-left: 0px;
//   }
// `;
// const CreditsGrid = styled.div`
//   height: 100%;
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-template-rows: repeat(${props => props.slot},1fr);
// `;
// const CreditsGridBox = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding: 0px 30px 0px;
//   text-align: right;
//   &:not(:first-child){
//     border-top: 1px solid #d5d9ed;
//   }

//   background: ${props => props.important ? LILAC : "#ffffff"};
//   color: ${props => props.important ? CHETWODEBLUE : "#303030"};
// `;
// const CreditsGridLabel = styled.div`
//   // border: 1px solid lime;
//   font-size: 11px;
// `;
// const CreditsGridValue = styled.div`
//   display: inline-block;
//   width: 100%;
//   font-size: 16px;
//   font-weight: 600;
//   line-height: 16px;
//   white-space: nowrap;
//   margin-top: 1px;
// `;


const Dashboard = ({refScrollerPage}) => {
  const GRAPH_MODE_DELTA = 0;
  const GRAPH_MODE_SUM = 1;

  const {projectUuid} = useParams();
  const [project, setProject] = useState(null);
  const [period, setPeriod] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(-1);
  const [periodHistory, setPeriodHistory] = useState(null);
  const [usage, setUsage] = useState(null);
  const [logs, setLogs] = useState(null);
  const [dayForHourlyDetail, setDayForHourlyDetail] = useState(null);
  const [graphMode, setGraphMode] = useState(GRAPH_MODE_DELTA);
  const [showLimit, setShowLimit] = useState(false);
  
  // const [listUsers, setListUsers] = useState([]);
  const [listProjects, setListProjects] = useState([]);

  const scrollToCredits = useRef();
  const [collapsedCredits, setCollapsedCredits] = useState(false);
  const scrollToStorage = useRef();
  const [collapsedStorage, setCollapsedStorage] = useState(false);
  const scrollToTransfer = useRef();
  const [collapsedTransfer, setCollapsedTransfer] = useState(false);
  const scrollToAssets = useRef();
  const [collapsedAssets, setCollapsedAssets] = useState(false);


  const [fetchError, setFetchError] = useState(false);


  useEffect(() => {
    if(checkPermission("project", "read")){
      ProjectsService.get(projectUuid).then((response) => {
        setProject(response);
        setPeriod(null);
      }).catch((error) => {
        setFetchError(formatError(error));
      });
    }
  }, [projectUuid]);

  useEffect(() => {
    if(checkPermission("period", "read") && checkPermission("period", "list")){
      if(period === null || selectedPeriod !== period.sequenceNumber){
        AccountService.getPeriod({
          "includeRecharges": true
        }, selectedPeriod).then((response1) => {
          if(response1 && response1){
            if(period === null){
              if(checkPermission("log", "list")){
                LogsService.list("", {
                  "project_uuid": projectUuid,
                  "start": response1.startDate,
                  "end": response1.currentPlan.planType === "PAID" ? response1.endDate : null
                }).then((response) => {
                  setLogs(response.hits.splice(0,10));
                }).catch((error) => {
                  setLogs(null);
                  setFetchError(formatError(error));
                });
              }
            }
            setPeriod(response1);
            setSelectedPeriod(response1.sequenceNumber);
            if(response1.currentPlan){  
              AccountService.listUsages({
                "unit": "HOURS",
                "startDate": response1.startDate,
                "endDate": response1.endDate,
                "projectUuid": projectUuid
              }).then((response2) => {
                if(response2 && response2.result){
                  setUsage(response2.result.length > 0 ? response2.result[0] : null);
                }  
              }).catch((error) => {
                setFetchError(formatError(error));
              });
            }
          }  
        }).catch((error) => {
          setFetchError(formatError(error));
        });
      }
    }else{
      if(checkPermission("log", "list")){
        LogsService.list("", {
          "project_uuid": projectUuid,
          "start": changeDate(Date.now(), -7, "days"),
        }).then((response) => {
          setLogs(response.hits.splice(0,10));
        }).catch((error) => {
          setLogs(null);
          setFetchError(formatError(error));
        });
      }
    }
  }, [period, selectedPeriod, projectUuid]);
      
  useEffect(() => {
    if(checkPermission("period", "read") && checkPermission("period", "list")){
      AccountService.listPeriods().then((response) => {
        if(response && response.result){
          setPeriodHistory(response.result.slice(0, 6).reverse());
        }
      }).catch((error) => {
        setPeriodHistory(null);
        setFetchError(formatError(error));
      });
    }
    if(checkPermission("project", "list")){
      ProjectsService.list().then((response) => {
        setListProjects(response);
      });
    }
  }, []);

  

  return (
    <OzContainer size="extra">
      {project ? 
        <>
          <Meta title={project.name + " - Dashboard"} />
          <PageHeader
            breadcrumbsIcon="data_usage"
            breadcrumbsText={["Dashboard", project ? project.name : "", project ? project.uuid : ""]}
            refScrollerPage={refScrollerPage}
            anchors={[
              checkPermission("period", "read") && checkPermission("period", "list") ? {
                "icon": "toll",
                "text": "Credits",
                "ref": scrollToCredits,
                "callback": () => {setCollapsedCredits(null); setTimeout( ()=> { setCollapsedCredits(false);},1)}
              } : null,
              checkPermission("period", "read") && checkPermission("period", "list") ? {
                "icon": "database",
                "text": "Storage",
                "ref": scrollToStorage,
                "callback": () => {setCollapsedStorage(null); setTimeout( ()=> { setCollapsedStorage(false);},1)}
              } : null,
              checkPermission("period", "read") && checkPermission("period", "list") ? {
                "icon": "cloud_download",
                "text": "Transfer",
                "ref": scrollToTransfer,
                "callback": () => {setCollapsedTransfer(null); setTimeout( ()=> { setCollapsedTransfer(false);},1)}
              } : null,
              checkPermission("period", "read") && checkPermission("period", "list") ? {
                "icon": "photo_library",
                "text": "Assets",
                "ref": scrollToAssets,
                "callback": () => {setCollapsedAssets(null); setTimeout( ()=> { setCollapsedAssets(false);},1)}
              } : null
            ]}
          />
        </>
      : null}

      <HealthyWrapper error={fetchError}>
        {checkPermission("period", "read") && checkPermission("period", "list") ? 
          <>
            <OzRow>
              {period && periodHistory && periodHistory.length > 1  ? periodHistory.map((val, i) => 
                <OzCol widthmd={2} key={i}>
                  <OzButton
                    width="100%"
                    variant={selectedPeriod === val.sequenceNumber ? "silvertree-o" : "push-black"}
                    onClick={() => setSelectedPeriod(val.sequenceNumber)}
                  >
                    {new Date(val.endDate) > new Date() ?
                      "Current"
                    :
                      getFormattedDate(val.startDate, false) + " - " + getFormattedDate(val.endDate, false)
                    }
                  </OzButton>
                </OzCol>
              ) : null }
            </OzRow>
            <br/>
            <OzRow>
              {period && project ?
                <OzCol widthmd="4">
                  {/* <CreditsDashboard>
                    <CreditsDashboardLeft> */}
                      <OzCard
                        height="100%"
                        headerLeft={
                          <>
                            <CardHeaderTitle title={"Credits usage " + (usage ? 
                              Math.round(
                                ((usage.scriptCredits + usage.storageCredits + usage.transferCredits) * 100 / period.creditsRecharged) * 10
                              ) /10
                            : 0) + "%"}  />
                          </>
                        }
                      >
                        <MegaBadgeCnt>
                          <center>
                            <OzChartPie
                              fnFormatValue={(val) => convertNumber(val)}
                              values={[{
                                id: project.uuid,
                                label: project.name,
                                color: CHETWODEBLUE,
                                value: usage ? (usage.scriptCredits + usage.storageCredits + usage.transferCredits) * 100 / period.creditsRecharged : 0
                              }]}
                            >
                              <ChartContent>
                                <ChartUsed>
                                  {usage ? convertNumber(usage.scriptCredits + usage.storageCredits + usage.transferCredits) : 0}
                                </ChartUsed>
                                <ChartUsedOf>of {convertNumber(period.creditsRecharged)}</ChartUsedOf>
                                {/* <ChartTotal>
                                  {convertNumber(period.creditsRecharged)}
                                </ChartTotal> */}
                              </ChartContent>
                            </OzChartPie>
                          </center>

                        </MegaBadgeCnt>
                      </OzCard>
                    {/* </CreditsDashboardLeft>
                    <CreditsDashboardRight>
                      <OzCard
                        height="100%"
                      > 
                        <CreditsGrid slots={period.currentPlan.planType === "PAID" ? 4 : 2}>
                          <CreditsGridBox>
                            <CreditsGridLabel>Plan included credits</CreditsGridLabel>
                            <CreditsGridValue>{convertNumber(period.currentPlan.credits)}</CreditsGridValue>
                          </CreditsGridBox>
                          <CreditsGridBox important>
                            <CreditsGridLabel>Total credits</CreditsGridLabel>
                            <CreditsGridValue>{convertNumber(period.creditsRecharged)}</CreditsGridValue>
                          </CreditsGridBox>
                        </CreditsGrid>
                      </OzCard>
                    </CreditsDashboardRight>
                  </CreditsDashboard>  */}
                </OzCol>
              : null }
              {period ?
                <OzCol widthmd="4">
                  <div>              
                    <OzCard
                      height="100%"
                      headerLeft={
                        <CardHeaderTitle 
                          title={<><OzTooltip>Average use over the current period</OzTooltip> Storage in use</>}
                        />
                      }
                    >
                      <MegaBadgeCnt>
                        <MegaBadge>{usage ? convertSize(usage.storageInUse) : 0}</MegaBadge>
                      </MegaBadgeCnt>
                    </OzCard>
                    <br/>
                    
                    <OzCard
                      height="100%"
                      headerLeft={
                        <CardHeaderTitle 
                          title={<><OzTooltip>Only transfer in download is charged; transfer in upload is free</OzTooltip> Data transfer consumed</>}
                        />
                      }
                    >
                      <MegaBadgeCnt>
                        <MegaBadge>{usage ? convertSize(usage.transferConsumed) : 0}</MegaBadge>
                      </MegaBadgeCnt>
                    </OzCard>
                    
                  </div>
                </OzCol>
              : null}
              {period ?
                <OzCol widthmd="4">
                  <OzCard
                    height="100%"
                    headerLeft={
                      <CardHeaderTitle 
                        title={<><OzTooltip>Number of assets processed during the period</OzTooltip> Assets processed</>}
                      />
                    }
                  >               
                    <AssetsCnt>
                      <AssetsBlock>
                        <OzRow>
                          <OzCol widthmd="6">
                            <MegaBadgeCnt>
                              <MegaBadge>{usage ? convertNumber(usage.assetsProcessed,2) : 0}</MegaBadge>
                            </MegaBadgeCnt>
                          </OzCol>
                          <OzCol widthmd="6">
                            <MegaBadgeCnt>
                              <MegaBadge variant="today">{usage ? (usage.days.filter(day => isSameDate(day.date, new Date(), "d")).length > 0 ? convertNumber(usage.days.filter(day => isSameDate(day.date, new Date(), "d"))[0].assetsProcessed,2) : 0) : 0}</MegaBadge>
                            </MegaBadgeCnt>
                            <center>Today</center>
                          </OzCol>
                        </OzRow>
                      </AssetsBlock>
                      
                      <Calendar>
                        {usage && period.currentPlan.periodDuration === "MONTHLY" ? 
                          <OzChartCalendar
                            minOpacity={0.07}
                            height={"100%"}
                            startDate={period.startDate}
                            endDate={period.endDate}
                            values={usage.days.map((item, i) => ({
                              date: new Date(item.date),
                              value: (item.assetsProcessed === null && new Date(item.date) <= new Date() ? 0 : item.assetsProcessed)
                            }))}
                          />
                        : null}
                      </Calendar>       
                    </AssetsCnt>         
                  </OzCard>
                </OzCol>
              : null }
            </OzRow>
            <br/>
          </>
        : null }        
        <OzRow>     
          {checkPermission("period", "read") && checkPermission("period", "list") ? 
            <>
              {period ? 
                <OzCol> 
                <ScrollRef ref={scrollToAssets} />           
                  <OzCard
                    height="100%"
                    collapsible={true}
                    collapsed={collapsedAssets}
                    headerLeft={
                      <CardHeaderTitle 
                        title="Detail of the assets processed"
                        subtitle={<>Usage since {getFormattedDate(period.startDate)}{new Date(period.endDate) < new Date() ? " to " + getFormattedDate(period.endDate ? period.endDate : new Date()) : null}</>}
                      />
                    }
                    headerCenterCollapsed={
                      usage && usage.days.length > 0 ?
                        <>
                          <br/>
                          <OzChartLine
                            height={"36px"}
                            fnFormatY={(val) => ""}
                            fnFormatValue={(val) => ""}
                            style={{
                              xAxis:{
                                showLabels: false
                              },
                              yAxis:{
                                showLabels: false
                              },
                              yLines:{
                                color: "transparent"
                              },
                              lines:{
                                color: CHETWODEBLUE,
                                size: 1
                              },
                              dots:{
                                borderColor: "white",
                                borderSize: 3,
                                color: CHETWODEBLUE,
                                hoverColor: CHETWODEBLUE,
                                size: 6
                              }
                            }}                  
                            values={usageToGraph(period, usage, false, "assets").map((item) => ({
                              value: item.value,
                              style: isSameDate(item.date, new Date(), "d") ? {
                              } : new Date(item.date) > new Date() ? {
                                // background: "#ffffff"
                              } : null
                            }))}
                          />
                        </>
                      : null
                    }
                    headerRight={
                      <CardPhrase>
                        <CardPhraseText>
                          Assets processed over the period
                          <CardPhraseSuperNumber>
                            {usage ? convertNumber(usage.assetsProcessed,0) : 0}
                          </CardPhraseSuperNumber>
                        </CardPhraseText>
                      </CardPhrase>
                    }
                    headerRightCollapsed={
                      <CardPhraseCollapsed>
                        <CardPhrase>
                          <CardPhraseText>
                            <CardPhraseSuperNumber>
                              {usage ? convertNumber(usage.assetsProcessed,0) : 0}
                            </CardPhraseSuperNumber>
                          </CardPhraseText>
                        </CardPhrase>
                      </CardPhraseCollapsed>
                    }
                  >
                    {usage && usage.days.length > 0?
                      <OzChartHistogram
                        fnFormatY={(val) => convertNumber(val)}
                        fnFormatValue={(val) => 
                          <MegaTooltip
                            visible={true}
                            title="Assets processed"
                            subtitle={getFormattedDate(val.date, false)}
                            value={convertNumber(val.value)}
                          />
                        }
                        values={usageToGraph(period, usage, false, "assets").map((item) => ({
                          label: <ChartXLabel>{
                            getArrayFromInterval(period.startDate, period.endDate ? period.endDate : new Date()).length > 31 ?
                              (new Date(item.date).getDate() === 1 ? 
                                <>{getFormattedMonth(item.date)}<br/>{new Date(item.date).getFullYear() % 100}</>
                              : null)
                            : 
                              <>{isSameDate(item.date, new Date(), "d") ? "TODAY" : new Date(item.date).getDate()}<br/>{getWeekDay(item.date)[0]}</>
                          }</ChartXLabel>,
                          style: isSameDate(item.date, new Date(), "d") ? {
                            labelColor: SILVERTREE,
                            barColor: SILVERTREE,
                            barColorHover: SILVERTREE
                          } : new Date(item.date) > new Date() ? {
                            // background: "#ffffff"
                          } : null,
                          bars: [{
                            date: item.date.split("T")[0],
                            value: item.value
                          }]
                        }))}
                      />
                    : <NoLog>No data available.</NoLog> }
                  </OzCard>
                </OzCol>
              : null }
            </>
          : null}
          {checkPermission("period", "read") && checkPermission("period", "list") ?                               
            <>
              {period ? 
                
                <OzCol>    
                  <ScrollRef ref={scrollToCredits} /> 
                  <OzCard
                    height="100%"
                    collapsible={true}
                    collapsed={collapsedCredits}
                    headerLeft={
                      <CardHeaderTitle 
                        title="Detail of the credits usage"
                        subtitle={<>Usage since {getFormattedDate(period.startDate)}{new Date(period.endDate) < new Date() ? " to " + getFormattedDate(period.endDate ? period.endDate : new Date()) : null}</>}
                      />
                    }
                    headerCenterCollapsed={
                      usage && usage.days.length > 0 ?
                        <OzChartLine
                          height={"36px"}
                          fnFormatY={(val) => ""}
                          fnFormatValue={(val) => ""}
                          style={{
                            xAxis:{
                              showLabels: false
                            },
                            yAxis:{
                              showLabels: false
                            },
                            yLines:{
                              color: "transparent"
                            },
                            lines:{
                              color: CHETWODEBLUE,
                              size: 1
                            }
                          }}                      
                          values={usageToGraph(period, usage, true, "credits").map((item) => ({
                            value: item.value,
                            style: isSameDate(item.date, new Date(), "d") ? {
                            } : new Date(item.date) > new Date() ? {
                              // background: "#ffffff"
                            } : null
                          }))}
                        />
                      : null
                    }
                    headerRight={
                      <CardPhrase>
                        <CardPhraseText>
                          Credits used over the period
                          <CardPhraseSuperNumber>
                            {usage ? convertNumber(usage.scriptCredits + usage.storageCredits + usage.transferCredits,0) : 0}
                          </CardPhraseSuperNumber>
                        </CardPhraseText>
                      </CardPhrase>
                    }
                    headerRightCollapsed={
                      <CardPhraseCollapsed>
                        <CardPhrase>
                          <CardPhraseText>
                            <CardPhraseSuperNumber>
                              {usage ? convertNumber(usage.scriptCredits + usage.storageCredits + usage.transferCredits,0) : 0}
                            </CardPhraseSuperNumber>
                          </CardPhraseText>
                        </CardPhrase>
                      </CardPhraseCollapsed>
                    }
                  >

                  {usage && usage.days.length > 0 ?
                    <>
                      <ChartModeContainer>
                        <ChartModeButton
                          active={graphMode === GRAPH_MODE_DELTA}
                          onClick={() => {
                            setGraphMode(GRAPH_MODE_DELTA);
                            setShowLimit(false);
                            setDayForHourlyDetail(null);
                          }}
                        >
                          <OzIcon name={"daily"} color={graphMode === GRAPH_MODE_DELTA ? "#ffffff" : "#303030"} size="15px" />&nbsp;Daily
                        </ChartModeButton>
                        <ChartModeButton
                          active={graphMode === GRAPH_MODE_SUM}
                          onClick={() => {
                            setGraphMode(GRAPH_MODE_SUM);
                            setDayForHourlyDetail(null);
                          }}
                        >
                          <OzIcon name={"sum"} color={graphMode === GRAPH_MODE_SUM ? "#ffffff" : "#303030"} size="15px" />&nbsp;Sum
                        </ChartModeButton>
                        {project.creditLimit ?
                          <ChartModeButton
                            active={showLimit}
                            onClick={() => setShowLimit(!showLimit)}
                          >
                            <OzIcon name={"plan-limit"} color={showLimit ? "#ffffff" : "#303030"} size="15px" />&nbsp;Limit
                          </ChartModeButton>
                        : null }
                      </ChartModeContainer>
                        {graphMode === GRAPH_MODE_DELTA ?
                          <OzChartHistogram
                            fnFormatY={(val) => convertNumber(val)}
                            fnFormatValue={(val) => 
                              <MegaTooltip
                                visible={true}
                                title="Credits usage"
                                subtitle={getFormattedDate(val.date, false)}
                                value={convertNumber(val.value,0)}
                              />
                            }
                            fnCallback={(ret) => {
                              setGraphMode(GRAPH_MODE_DELTA);
                              setDayForHourlyDetail(ret.date);
                            }}                   
                            values={usageToGraph(period, usage, false, "credits").map((item) => ({
                              label: <ChartXLabel>{
                                getArrayFromInterval(period.startDate, period.endDate ? period.endDate : new Date()).length > 31 ? 
                                  (new Date(item.date).getDate() === 1 ? 
                                    <>{getFormattedMonth(item.date)}<br/>{new Date(item.date).getFullYear() % 100}</>
                                  : null)
                                : 
                                  <>{isSameDate(item.date, new Date(), "d") ? "TODAY" : new Date(item.date).getDate()}<br/>{getWeekDay(item.date)[0]}</>
                              }</ChartXLabel>,
                              style: isSameDate(item.date, new Date(), "d") ? {
                                labelColor: SILVERTREE,
                                barColor: SILVERTREE,
                                barColorHover: SILVERTREE
                              } : new Date(item.date) > new Date() ? {
                                // background: "#ffffff"
                              } : null,
                              bars: [{
                                date: item.date.split("T")[0],
                                value: item.value
                              }]
                            }))}
                          />
                        : null }
                        {graphMode === GRAPH_MODE_SUM ?
                          <OzChartLine
                            fnFormatY={(val) => convertNumber(val)}
                            fnFormatValue={(val) => 
                              <MegaTooltip
                                visible={true}
                                title="Credits usage"
                                subtitle={getFormattedDate(val.date, false)}
                                value={convertNumber(val.value,0)}
                              />
                            }
                            fnCallback={(ret) => {
                              setGraphMode(GRAPH_MODE_DELTA);
                              setDayForHourlyDetail(ret.date);
                            }}
                            // limits={showLimit ? [{
                            //   value: project.creditLimit,
                            //   labelText: "Project Limit"
                            // }] : []}
                            style={{
                              lines:{
                                color: CHETWODEBLUE,
                                size: 1
                              },
                              dots:{
                                borderColor: "white",
                                borderSize: 3,
                                color: CHETWODEBLUE,
                                hoverColor: CHETWODEBLUE,
                                size: 6
                              }
                            }}                
                            values={usageToGraph(period, usage, true, "credits").map((item) => ({
                              label: <ChartXLabel>{
                                getArrayFromInterval(period.startDate, period.endDate ? period.endDate : new Date()).length > 31 ?
                                  (new Date(item.date).getDate() === 1 ? 
                                    <>{getFormattedMonth(item.date)}<br/>{new Date(item.date).getFullYear() % 100}</>
                                  : null)
                                : 
                                  <>{isSameDate(item.date, new Date(), "d") ? "TODAY" : new Date(item.date).getDate()}<br/>{getWeekDay(item.date)[0]}</>
                              }</ChartXLabel>,
                              value: item.value,
                              style: isSameDate(item.date, new Date(), "d") ? {
                                labelColor: SILVERTREE,
                                barColor: SILVERTREE,
                                barColorHover: SILVERTREE
                              } : new Date(item.date) > new Date() ? {
                                // background: "#ffffff"
                              } : null
                            }))}
                          />
                        : null }
                        {dayForHourlyDetail ?
                          <>
                            <hr/>
                            <CardHeaderTitle 
                              title="Hourly credits usage"
                              subtitle={<>Usage: {getFormattedDate(dayForHourlyDetail)}</>}
                            />

                            {graphMode === GRAPH_MODE_DELTA ?
                              <OzChartHistogram
                                fnFormatY={(val) => convertNumber(val)}
                                fnFormatValue={(val) => 
                                  <MegaTooltip
                                    visible={true}
                                    title="Credits usage"
                                    subtitle={val.time+":00"}
                                    value={convertNumber(val.value,0)}
                                  />
                                }
                                values={Array.from({ length: 24 }, (value, index) => index).map((hour, i) => ({
                                  label: isSameDate(dayForHourlyDetail, new Date(), "d") && new (Date()).getHours() === hour ? "NOW" : hour+":00",
                                  style: isSameDate(dayForHourlyDetail, new Date(), "d") && new (Date()).getHours() === hour ? {
                                    labelColor: SILVERTREE,
                                    barColor: SILVERTREE+"26",
                                    barColorHover: SILVERTREE+"aa"
                                  } : new Date(dayForHourlyDetail) > new Date() ? {
                                    // background: "#ffffff"
                                  } : null,
                                  bars: [{
                                    time: hour,
                                    value: usage.days.filter(item => item.date === dayForHourlyDetail).length > 0  ?
                                      (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour).length > 0 ? 
                                        (
                                            (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].scriptCredits ? usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].scriptCredits : 0)
                                          + (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].storageCredits ? usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].storageCredits : 0) 
                                          + (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].transferCredits ? usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].transferCredits : 0)
                                        )
                                      : 0)
                                  : 0
                                  }]
                                }))}
                              />
                            : null }
                            {/* {graphMode === GRAPH_MODE_SUM ?
                              <OzChartLine
                                style={{
                                  lines:{
                                    color: CHETWODEBLUE,
                                    size: 1
                                  },
                                  dots:{
                                    borderColor: "white",
                                    borderSize: 3,
                                    color: CHETWODEBLUE,
                                    hoverColor: CHETWODEBLUE,
                                    size: 6
                                  }
                                }}
                                fnFormatY={(val) => convertNumber(val)}
                                fnFormatValue={(val) => <OzTag variant="chetwodeblue" size="small">{convertNumber(val,0)}</OzTag>}
                                values={Array.from({ length: 24 }, (value, index) => index).map((hour, i) => ({
                                  label: isSameDate(dayForHourlyDetail, new Date(), "d") && new (Date()).getHours() === hour ? "NOW" : hour+":00",
                                  style: isSameDate(dayForHourlyDetail, new Date(), "d") && new (Date()).getHours() === hour ? {
                                    labelColor: SILVERTREE,
                                    barColor: SILVERTREE+"26",
                                    barColorHover: SILVERTREE+"aa"
                                  } : null,
                                  value: usage.days.filter(item => item.date === dayForHourlyDetail).length > 0  ?
                                      (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour).length > 0 ? 
                                        (
                                            (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].scriptCredits ? usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].scriptCredits : 0)
                                          + (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].storageCredits ? usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].storageCredits : 0) 
                                          + (usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].transferCredits ? usage.days.filter(item => item.date === dayForHourlyDetail)[0].hours.filter(item => item.hour === hour)[0].transferCredits : 0)
                                        )
                                      : 0)
                                  : 0
                                }))}
                                
                                values={hourlyUsage.charges.map((item, i) => ({
                                  label: isSameDate(item.date, new Date(), "h") ? "NOW" : getFormattedTime(item.date, false),
                                  value: item.creditSpent,
                                  style: isSameDate(item.date, new Date(), "h") ? {
                                    labelColor: SILVERTREE,
                                    barColor: SILVERTREE+"26",
                                    barColorHover: SILVERTREE+"aa"
                                  } : null
                                }))}
                              />
                            : null } */}
                          </>
                        : null }
                      </>
                    : <NoLog>No data available.</NoLog> }
                  </OzCard>
                </OzCol>
              : null }
            </>
          : null}
          {checkPermission("period", "read") && checkPermission("period", "list") ? 
            <>
              {period ? 
                <OzCol>
                  <ScrollRef ref={scrollToStorage} /> 
                  <OzCard
                    height="100%"
                    collapsible={true}
                    collapsed={collapsedStorage}
                    headerLeft={
                      <CardHeaderTitle 
                        title="Detail of the storage usage"
                        subtitle={<>Usage since {getFormattedDate(period.startDate)}{new Date(period.endDate) < new Date() ? " to " + getFormattedDate(period.endDate ? period.endDate : new Date()) : null}</>}
                      />
                    }
                    headerCenterCollapsed={
                      usage && usage.days.length > 0 ?
                        <OzChartLine
                          height={"36px"}
                          fnFormatY={(val) => ""}
                          fnFormatValue={(val) => ""}
                          style={{
                            xAxis:{
                              showLabels: false
                            },
                            yAxis:{
                              showLabels: false
                            },
                            yLines:{
                              color: "transparent"
                            },
                            lines:{
                              color: CHETWODEBLUE,
                              size: 1
                            },
                            dots:{
                              borderColor: "white",
                              borderSize: 3,
                              color: CHETWODEBLUE,
                              hoverColor: CHETWODEBLUE,
                              size: 6
                            }
                          }}                      
                          values={usageToGraph(period, usage, false, "storage").map((item) => ({
                            value: item.value,
                            style: isSameDate(item.date, new Date(), "d") ? {
                            } : new Date(item.date) > new Date() ? {
                              // background: "#ffffff"
                            } : null
                          }))}
                        />
                      : null
                    }
                    headerRight={
                      <CardPhrase>
                        <CardPhraseText>
                          Storage used over the period
                          <CardPhraseSuperNumber>
                            {usage ? convertSize(usage.storageInUse,2) : 0}
                          </CardPhraseSuperNumber>
                        </CardPhraseText>
                      </CardPhrase>
                    }
                    headerRightCollapsed={
                      <CardPhraseCollapsed>
                        <CardPhrase>
                          <CardPhraseText>
                            <CardPhraseSuperNumber>
                              {usage ? convertSize(usage.storageInUse,2) : 0}
                            </CardPhraseSuperNumber>
                          </CardPhraseText>
                        </CardPhrase>
                      </CardPhraseCollapsed>
                    }
                  >
                    {usage && usage.days.length > 0 ?
                      <OzChartHistogram
                        fnFormatY={(val) => convertSize(val)}
                        fnFormatValue={(val) => 
                          <MegaTooltip
                            visible={true}
                            title="Storage usage"
                            subtitle={getFormattedDate(val.date, false)}
                            value={convertSize(val.value,2)}
                          />
                        }
                        values={usageToGraph(period, usage, false, "storage").map((item) => ({
                          label: <ChartXLabel>{
                            getArrayFromInterval(period.startDate, period.endDate ? period.endDate : new Date()).length > 31 ?
                              (new Date(item.date).getDate() === 1 ? 
                                <>{getFormattedMonth(item.date)}<br/>{new Date(item.date).getFullYear() % 100}</>
                              : null)
                            : 
                              <>{isSameDate(item.date, new Date(), "d") ? "TODAY" : new Date(item.date).getDate()}<br/>{getWeekDay(item.date)[0]}</>
                          }</ChartXLabel>,
                          style: isSameDate(item.date, new Date(), "d") ? {
                            labelColor: SILVERTREE,
                            barColor: SILVERTREE,
                            barColorHover: SILVERTREE
                          } : new Date(item.date) > new Date() ? {
                            // background: "#ffffff"
                          } : null,
                          bars: [{
                            date: item.date.split("T")[0],
                            value: item.value
                          }]
                        }))}
                      />
                    : <NoLog>No data available.</NoLog> }
                  </OzCard>
                </OzCol>
              : null }
              </>
            : null}
            {checkPermission("period", "read") && checkPermission("period", "list") ? 
              <>
              {period ? 
                <OzCol>      
                  <ScrollRef ref={scrollToTransfer} />      
                  <OzCard
                    height="100%"
                    collapsible={true}
                    collapsed={collapsedTransfer}
                    headerLeft={
                      <CardHeaderTitle 
                        title="Detail of the data transfer consumed"
                        subtitle={<>Usage since {getFormattedDate(period.startDate)}{new Date(period.endDate) < new Date() ? " to " + getFormattedDate(period.endDate ? period.endDate : new Date()) : null}</>}
                      />
                    }
                    headerCenterCollapsed={
                      usage && usage.days.length > 0 ?
                        <OzChartLine
                          height={"36px"}
                          fnFormatY={(val) => ""}
                          fnFormatValue={(val) => ""}
                          style={{
                            xAxis:{
                              showLabels: false
                            },
                            yAxis:{
                              showLabels: false
                            },
                            yLines:{
                              color: "transparent"
                            },
                            lines:{
                              color: CHETWODEBLUE,
                              size: 1
                            },
                            dots:{
                              borderColor: "white",
                              borderSize: 3,
                              color: CHETWODEBLUE,
                              hoverColor: CHETWODEBLUE,
                              size: 6
                            }
                          }}                   
                          values={usageToGraph(period, usage, false, "transfer").map((item) => ({
                            value: item.value,
                            style: isSameDate(item.date, new Date(), "d") ? {
                            } : new Date(item.date) > new Date() ? {
                              // background: "#ffffff"
                            } : null
                          }))}
                        />
                      : null
                    }
                    headerRight={
                      <CardPhrase>
                        <CardPhraseText>
                          Data transfer used over the period
                          <CardPhraseSuperNumber>
                            {usage ? convertSize(usage.transferConsumed,2) : 0}
                          </CardPhraseSuperNumber>
                        </CardPhraseText>
                      </CardPhrase>
                    }
                    headerRightCollapsed={
                      <CardPhraseCollapsed>
                        <CardPhrase>
                          <CardPhraseText>
                            <CardPhraseSuperNumber>
                              {usage ? convertSize(usage.transferConsumed,2) : 0}
                            </CardPhraseSuperNumber>
                          </CardPhraseText>
                        </CardPhrase>
                      </CardPhraseCollapsed>
                    }
                  >
                    {usage && usage.days.length > 0 ?
                      <OzChartHistogram
                        fnFormatY={(val) => convertSize(val)}
                        fnFormatValue={(val) => 
                          <MegaTooltip
                            visible={true}
                            title="Data transfer consumed"
                            subtitle={getFormattedDate(val.date, false)}
                            value={convertSize(val.value,2)}
                          />
                        }
                        values={usageToGraph(period, usage, false, "transfer").map((item) => ({
                          label: <ChartXLabel>{
                            getArrayFromInterval(period.startDate, period.endDate ? period.endDate : new Date()).length > 31 ?
                              (new Date(item.date).getDate() === 1 ? 
                                <>{getFormattedMonth(item.date)}<br/>{new Date(item.date).getFullYear() % 100}</>
                              : null)
                            : 
                              <>{isSameDate(item.date, new Date(), "d") ? "TODAY" : new Date(item.date).getDate()}<br/>{getWeekDay(item.date)[0]}</>
                          }</ChartXLabel>,
                          style: isSameDate(item.date, new Date(), "d") ? {
                            labelColor: SILVERTREE,
                            barColor: SILVERTREE,
                            barColorHover: SILVERTREE
                          } : new Date(item.date) > new Date() ? {
                            // background: "#ffffff"
                          } : null,
                          bars: [{
                            date: item.date.split("T")[0],
                            value: item.value
                          }]
                        }))}
                      />
                    : <NoLog>No data available.</NoLog> }
                  </OzCard>
                </OzCol>
              : null }
            </>
          : null}
          
          {checkPermission("log", "list") ? 
            <OzCol>
              <OzCard
                height="100%"
                headerLeft={<CardHeaderTitle title="Last logs" />}
                footerRight={
                  <Link to={"/project/"+projectUuid+"/logs"}>
                    <Detail>Detail</Detail>
                  </Link>
                }
              >
                {logs && logs.length > 0 ?
                  <LogTableWrapper>
                    <OzTable
                      columns={[
                        "Date",
                        "Time",
                        "File name",
                        "Activity",
                        "Size",
                        <center>Cost</center>,
                        <center>Exec</center>
                      ]}
                      values={
                        logs.slice(0,5).map(item => ({
                          style: {
                            color: item.result === "ERROR" ? "#FF4848" : null
                          },
                          id: item.document.id,
                          accordion: <Accordion>
                            <AccordionLeft full={item.document.result !== "ERROR" && item.document.result !== "WARNING"}>
                              <table>
                                <LogRow>
                                  <LogKey>Date</LogKey>
                                  <LogValue>{getFormattedDate(item.document.create_datetime)}</LogValue>
                                </LogRow>
                                <LogRow>
                                  <LogKey>Time</LogKey>
                                  <LogValue>{getFormattedTime(item.document.create_datetime)}</LogValue>
                                </LogRow>
                                {item.document.source_file_key ? 
                                  <LogRow>
                                    <LogKey>Source file name</LogKey>
                                    <LogValue>
                                      <OzCopyable>{item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)}</OzCopyable>
                                    </LogValue>
                                  </LogRow>
                                : null}
                                {item.document.source_file_size ? 
                                  <LogRow>
                                    <LogKey>Source file size</LogKey>
                                    <LogValue>{convertSize(item.document.source_file_size)}</LogValue>
                                  </LogRow>
                                : null}
                                {/* {item.document.user_uuid ? 
                                  <LogRow>
                                    <LogKey>Company</LogKey>
                                    <LogValue> 
                                      {listUsers.filter(val => val.uuid === item.document.user_uuid).length > 0 ?
                                        listUsers.filter(val => val.uuid === item.document.user_uuid).map(user =>
                                          user.company
                                        )
                                      : "-"}
                                    </LogValue>
                                  </LogRow>
                                : null}
                                {item.document.user_uuid ? 
                                  <LogRow>
                                    <LogKey>User</LogKey>
                                    <LogValue> 
                                      {listUsers.filter(val => val.uuid === item.document.user_uuid).length > 0 ?
                                        listUsers.filter(val => val.uuid === item.document.user_uuid).map(user =>
                                          user.givenName + " " + user.familyName
                                        )
                                      : "-"}
                                    </LogValue>
                                  </LogRow>
                                : null} */}
                                {item.document.project_uuid ? 
                                  <LogRow>
                                    <LogKey>Project</LogKey>
                                    <LogValue> 
                                      {listProjects.filter(val => val.uuid === item.document.project_uuid).length > 0 ?
                                        listProjects.filter(val => val.uuid === item.document.project_uuid).map(project =>
                                          project.name
                                        )
                                      : "-"}
                                    </LogValue>
                                  </LogRow>
                                : null}
                                {item.document.project_uuid ? 
                                  <LogRow>
                                    <LogKey>Project UUID</LogKey>
                                    <LogValue> 
                                      <OzCopyable>{item.document.project_uuid}</OzCopyable>
                                    </LogValue>
                                  </LogRow>
                                : null}
                                {item.document.consumer_type ? 
                                  <LogRow>
                                    <LogKey>Consumer type</LogKey>
                                    <LogValue>{item.document.consumer_type}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.script_name ? 
                                  <LogRow>
                                    <LogKey>Script</LogKey>
                                    <LogValue>{item.document.script_name}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.destination_type ? 
                                  <LogRow>
                                    <LogKey>Destination type</LogKey>
                                    <LogValue>{item.document.destination_type}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.processing_time_ms ? 
                                  <LogRow>
                                    <LogKey>Process time (ms)</LogKey>
                                    <LogValue>{item.document.processing_time_ms}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.response_size ? 
                                  <LogRow>
                                    <LogKey>Response size</LogKey>
                                    <LogValue>{convertSize(item.document.response_size)}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.assets_processed ? 
                                  <LogRow>
                                    <LogKey>Assets processed</LogKey>
                                    <LogValue>{item.document.assets_processed}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.credit_price ? 
                                  <LogRow>
                                    <LogKey>Credit price</LogKey>
                                    <LogValue>{item.document.credit_price}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.transfer_bytes ? 
                                  <LogRow>
                                    <LogKey>Transfer bytes</LogKey>
                                    <LogValue>{convertSize(item.document.transfer_bytes)}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.storage_bytes ? 
                                  <LogRow>
                                    <LogKey>Storage bytes</LogKey>
                                    <LogValue>{convertSize(item.document.storage_bytes)}</LogValue>
                                  </LogRow>
                                : null}
                                {item.document.job_uuid ? 
                                  <LogRow>
                                    <LogKey>Job UUID</LogKey>
                                    <LogValue>
                                      <OzCopyable>{item.document.job_uuid}</OzCopyable>
                                    </LogValue>
                                  </LogRow>
                                : null}
                              </table>
                            </AccordionLeft>
                            {item.document.result === "ERROR" || item.document.result === "WARNING" ?
                              <AccordionRight>
                              
                                <table>
                                  {item.document.error_code ? 
                                    <LogRow>
                                      <LogKey>Error</LogKey>
                                      <LogValue>{item.document.error_code}</LogValue>
                                    </LogRow>
                                  : null}
                                  {item.document.error_description ? 
                                    <LogRow>
                                      <LogKey>Description</LogKey>
                                      <LogValue>{item.document.error_description}</LogValue>
                                    </LogRow>
                                  : null}
                                  {item.document.error_scope ? 
                                    <LogRow>
                                      <LogKey>Type</LogKey>
                                      <LogValue>{item.document.error_scope}</LogValue>
                                    </LogRow>
                                  : null}
                                </table>
                              </AccordionRight>
                            : null}
                          </Accordion>,
                          values: [
                            <Dark>
                              {getFormattedDate(item.document.create_datetime)}
                            </Dark>,
                            <Light>
                              {getFormattedTime(item.document.create_datetime)}
                            </Light>,
                            <Dark>
                              {item.document.source_file_key ? 
                                ((item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1))
                                .length > 20 ? 
                                  item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)
                                  .slice(0, 17)
                                  +"..."
                                :
                                  item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)
                                )
                              : null}
                            </Dark>,
                            <Dark>
                              {item.document.consumer_type === "SCRIPT" ? 
                                item.document.script_name
                              :
                                item.document.consumer_type
                              }
                            </Dark>,
                            item.document.consumer_type === "STORAGE" ?
                              convertSize(item.document.storage_in_use)
                            : item.document.consumer_type === "TRANSFER" ?
                              convertSize(item.document.transfer_consumed)
                            :
                              convertSize(item.document.source_file_size),
                            <LogCost>{item.document.credit_price}</LogCost>,
                            <LogExecution status={item.document.result}>
                              <span className="material-symbols-outlined">{
                                item.document.result === "SUCCESS" ? 
                                  "done"
                                : item.document.result === "WARNING" ? 
                                  "warning"
                                : item.document.result === "ERROR" ? 
                                  "close"
                                : item.document.result === "ONGOING" ? 
                                  "hourglass_empty"
                                : null
                              }</span>
                            </LogExecution>
                          ]
                        }))
                      }
                    />
                  </LogTableWrapper>
                :
                  <NoLog>No log during this period.</NoLog>
                }
              </OzCard>
            </OzCol>
          : null }
        </OzRow>
      </HealthyWrapper>
    </OzContainer>
  )
}

export default Dashboard;