import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {CHETWODEBLUE, BORDER, LILAC, OzTooltip, OzCheck, OzRow, OzCol, OzSelect, OzInput, OzCheckbox, OzButton, OzTextarea, OzProjectTag} from '@ozwol/webui';

import ScriptsService from '../../services/ScriptsService';
import ProjectsService from '../../services/ProjectsService';

import HotfoldersService from '../../services/HotfoldersService';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ModalSuccess from '../../components/ModalSuccess';
import ErrorMessage from '../../components/ErrorMessage';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, testValidField, clearObject} from '../../helpers';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const PreHeader = styled.div`
  padding: 5px 20px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${LILAC};
`;

const Header = styled.div`
  padding: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: 1px solid ${BORDER};
  color: ${CHETWODEBLUE};
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const HeaderLeft = styled.div`
`;
const HeaderRight = styled.div`
`;
const Footer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${BORDER};
`;
const Container = styled.div`
  padding: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: scroll;
`;

const Block = styled.div`
  border-bottom: 1px solid ${BORDER};
  padding-bottom: 30px;
  margin-bottom: 30px;
`;

const ParamGroupTitle = styled.div`
  font-weight: bold;
`;
// const ParamGroupFields = styled.div`
//   display: grid;
//   grid: auto / 1fr 1fr 1fr 1fr;
//   grid-gap: 10px;
// `;
// const ParamGroupField = styled.div`
// `;
const ParamGroupFieldInput = styled.div`
  margin-top: -20px;
  width: 100%;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: row;
`;
const ParamGroupFieldNullable = styled.div`
  padding-top: 50px;
  padding-right: 5px;
`;
const CodeSide = styled.div`
  // border: 1px solid red;
  margin-top: -20px;
  margin-left: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 40px);

`;
const CodeButtons = styled.div`
  height: 44px;
  padding: 5px 5px 5px;

`;
const CodeContent = styled.div`
  position: relative;
  height: calc(100% - 44px);
`;
const CodeTextarea = styled.div`
  height: calc(100% - ${props => props.valid ? "0px" : "30px"});
  & *{
    height: 100%;  
  }
  & > div > *:last-child > *{
      background-color: #EAF0FB;
      font-family: 'Fira Code', consolas;
      border-radius: 0px;
  }

`;


const InsiderHotfoldersActionsSingleDetail = () => {
  const {addOverlay} = useContext(OverlayContext);
  const {hotfolderUuid, actionUuid} = useParams();
  const [item, setItem] = useState();
  const [project, setProject] = useState();
  const [script, setScript] = useState();
  const [listScripts, setListScripts] = useState([]);
  const [tempScriptValues, setTempScriptValues] = useState("");
  const [tempOutputFormat, setTempOutputFormat] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  const [fetchError, setFetchError] = useState(false);
  const [showJson, setShowJson] = useState(0);


  const getItem = useCallback(() => {
    HotfoldersService.adminGetActions(actionUuid).then((response) => {
      ProjectsService.adminGet(response.projectUuid).then((response) => {
        setProject(response);
      }).catch((error) => {
        setFetchError(formatError(error));
      });
      ScriptsService.get(response.scriptUuid).then((response2) => {
        delete response2.params["image"]; //rimuovo image dalla lista dei parametri dello script
        setScript(response2);
        let tempItem = {...response};
        let tempScriptValues = {...response2.defaults};
        tempItem.scriptValues = {...tempScriptValues, ...tempItem.scriptValues};
        setItem(tempItem);
      });
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [actionUuid]);

  // const toggleActiveItem = useCallback(() => {
  //   HotfoldersService.adminUpdate(item.uuid, {active: !item.active}).then((response) => {
  //     getItem();
  //   }).catch((error) => {
      // setErrorMessage(formatError(error));
  //   });
  // }, [item, getItem]);


  useEffect(() => {
    getItem();
  }, [actionUuid, getItem]);

  useEffect(() => {
    if(item){
      setTempScriptValues(JSON.stringify(item.scriptValues, null, 2));
      setTempOutputFormat(JSON.stringify(item.outputFormat, null, 2));
    }else{
      setTempScriptValues("");
      setTempOutputFormat("");
    }
  }, [item]);

  


  useEffect(() => {     
    ScriptsService.list().then((response) => {
      setListScripts(response.result);
    });
    
  }, []);


  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && script){
      if(!testValidField(item.outputFormat.type, true)){
        valid = false;
      }
      let mandatoryFields = Object.keys(script.params).filter(k => script.params[k].mandatory && k !== "image" && k !== "file");
      mandatoryFields.forEach(k => {
        if(!testValidField(item.scriptValues[k], true)){
          valid = false;
        }        
      });

    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, script]);
  
  const updateItem = useCallback(() => {
    setErrorMessage();
    HotfoldersService.adminUpdateActions(actionUuid, clearObject(item, [
      "scriptValues",
      "outputFormat"
    ])).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setItem(response);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [actionUuid, item, addOverlay]);

  const updateField = (name, value, nullable = false) => {
    let tempItem = {...item};
    let values = {...tempItem.scriptValues};
    if(nullable){
      values[name] = value;
    }else{
      values[name] = value !== null ? value : "";
    }
    tempItem.scriptValues = values;
    setItem(tempItem);
  }

  
  return (
    <>
      <HealthyWrapper error={fetchError} loading={!item}>
        <Wrapper>
          <PreHeader>
            {project ?
              <OzProjectTag
                name={project.name}
                color={project.color}
                uuid={project.uuid}
                variant={"chetwodeblue"}
                direction={"horizontal"}
              />  
            : null}
          </PreHeader>
          <Header>
            <HeaderLeft>
              <CardHeaderTitle title={
                <>
                  {item && item.scriptUuid && listScripts.length > 0 && listScripts.filter(x => x.uuid === item.scriptUuid).length > 0 ? 
                    listScripts.filter(x => x.uuid === item.scriptUuid)[0].name
                  : null}
                  {item && item.description ? " | " + item.description : ""}
                </>
              } />
            </HeaderLeft>
            <HeaderRight>
              <OzButton noMinWidth={true} variant={"push-black"} onClick={() => setShowJson(showJson === 0 ? 1 : 0)}>
                {showJson === 0 ? 
                  <span className="material-symbols-outlined">code</span>
                :
                  <span className="material-symbols-outlined">apps</span>
                }
              </OzButton>
            </HeaderRight>
          </Header>
          <Container size="fluid">          
            {showJson !== 0 ? 
              <CodeSide>
                <CodeButtons>
                  <OzButton variant={showJson === 1 ? "selected" : "push-black"} onClick={() => setShowJson(1)}>
                    Script values
                  </OzButton> &nbsp;
                  <OzButton variant={showJson === 2 ? "selected" : "push-black"} onClick={() => setShowJson(2)}>
                    Output format
                  </OzButton>
                </CodeButtons>
                {showJson === 1 ? 
                  <CodeContent>
                    <CodeTextarea valid={JSON.stringify(item.scriptValues, null, 2) === tempScriptValues}>
                      <OzTextarea 
                        width={"100%"}
                        height={"100%"}
                        value={tempScriptValues}
                        onChange={(val) => {
                          setTempScriptValues(val);
                          if (/^[\],:{}\s]*$/.test(val.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))){
                            let tempItem = {...item};
                            tempItem.scriptValues = JSON.parse(val);
                            setItem(tempItem);
                          }                      
                        }}
                      />
                    </CodeTextarea>
                    {JSON.stringify(item.scriptValues, null, 2) !== tempScriptValues ?
                      <ErrorMessage noMargin>JSON non valido, modifiche non salvate</ErrorMessage>
                    : null}
                  </CodeContent>
                :
                  <CodeContent>
                    <CodeTextarea valid={JSON.stringify(item.outputFormat, null, 2) === tempOutputFormat}>
                      <OzTextarea 
                        width={"100%"}
                        height={"100%"}
                        value={tempOutputFormat}
                        onChange={(val) => {
                          setTempOutputFormat(val);
                          if (/^[\],:{}\s]*$/.test(val.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))){
                            let tempItem = {...item};
                            tempItem.outputFormat = JSON.parse(val);
                            setItem(tempItem);
                          }  
                        }}
                      />
                    </CodeTextarea>
                    {JSON.stringify(item.outputFormat, null, 2) !== tempOutputFormat ?
                      <ErrorMessage noMargin>JSON non valido, modifiche non salvate</ErrorMessage>
                    : null}
                  </CodeContent>
                }
              </CodeSide>
            : 
              <>
                {script && script.params && item.scriptValues ?
                  <>
                    {[...new Set(Object.keys(script.params).sort((k1, k2) => parseInt(script.params[k1].index) - parseInt(script.params[k2].index)).map((key)=>script.params[key].group))].map((kGroup, iGroup) =>
                      //kGroup ? 
                      Object.keys(script.params).filter((key)=>script.params[key].group === kGroup).length > 0 ? 
                        <Block key={iGroup}>
                          <OzRow>
                            {kGroup ? 
                              <OzCol widthmd="12">
                                <ParamGroupTitle>{kGroup}</ParamGroupTitle>
                              </OzCol>
                            : null}
                            
                            {Object.keys(script.params).sort((k1, k2) => parseInt(script.params[k1].index) - parseInt(script.params[k2].index)).filter((key)=>script.params[key].group === kGroup).map((kParam, iParam) =>
                              script.params[kParam].index !== "0" ? 
                                <OzCol widthmd="6" key={iParam}>

                                  <ParamGroupFieldInput>
                                    {script.params[kParam].description[0] === "§" ?
                                      <ParamGroupFieldNullable>
                                        <OzCheck
                                          onChange={(val) => {
                                            updateField(kParam, val ? "" : null, true);
                                            // console.log(val);
                                          }}
                                          selected={item.scriptValues[kParam] !== null}
                                          width="100%"
                                        />
                                      </ParamGroupFieldNullable>
                                    : null }
                                    {script.params[kParam].type === "string" ?
                                      (script.params[kParam].admittedValues && script.params[kParam].admittedValues.length > 0 ?
                                        <OzSelect
                                          label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                                          onChange={(val) => updateField(kParam, val)}
                                          selected={item.scriptValues[kParam]}
                                          values={script.params[kParam].admittedValues.length > 0 ? script.params[kParam].admittedValues.map(value => ({"name": value, "value": value})) : []}
                                          width="100%"
                                          maxHeight={"200px"}
                                          required={script.params[kParam].mandatory}
                                        />
                                      :
                                        <OzInput
                                          label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                                          onChange={(val) => updateField(kParam, val)}
                                          value={item.scriptValues[kParam] !== null ? item.scriptValues[kParam] : ""}
                                          width="100%"
                                          required={script.params[kParam].mandatory}
                                          disabled={item.scriptValues[kParam] === null}
                                        />
                                      )
                                    : script.params[kParam].type === "number" ? //TODO forza numero
                                      <OzInput
                                        label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                                        onChange={(val) => updateField(kParam, /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(val) ? Number(val) : item.scriptValues[kParam])}
                                        value={item.scriptValues[kParam] !== null ? item.scriptValues[kParam] : ""}
                                        width="100%"
                                        required={script.params[kParam].mandatory}
                                        disabled={item.scriptValues[kParam] === null}
                                      />
                                    : script.params[kParam].type === "boolean" ?
                                      <OzCheckbox
                                        label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                                        onChange={(val) => updateField(kParam, (val))}
                                        selected={item.scriptValues[kParam] ? item.scriptValues[kParam] : 0}
                                        required={script.params[kParam].mandatory}
                                      />
                                    :
                                      null //"MISSING TYPE: " + script.params[kParam].type
                                    }
                                  </ParamGroupFieldInput>
                                </OzCol>
                              : null
                            )}
                          </OzRow>
                        </Block>
                      : null
                    )}

                    <Block>

                    <OzRow>
                      <OzCol widthmd="12">
                        <ParamGroupTitle>Output format</ParamGroupTitle>
                      </OzCol>
                      <OzCol widthmd="12">
                        <OzSelect
                          label="Type"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp){
                              temp = {};
                            }
                            temp.type = val;
                            switch(val.toUpperCase()){
                              case "JPG":
                              case "JPEG":
                                temp.quality = 90;
                                temp.optimize = true;
                                break;
                              case "PNG":
                                temp.quality = 0;
                                temp.progressive = false;
                                temp.optimize = true;
                                break;
                              case "WEBP":
                                temp.quality = 90;
                                temp.progressive = false;
                                break;
                              case "PSD":
                                temp.minFileSize = 0;
                                temp.maxFileSize = 0;
                                temp.quality = 0;
                                temp.progressive = false;
                                break;
                              default:
                                break;
                            }
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          selected={item.outputFormat && item.outputFormat.type ? (item.outputFormat.type.toUpperCase() === "JPEG" ? "JPG" : item.outputFormat.type.toUpperCase()) : "PNG"}
                          values={[{
                            name: "PNG",
                            value: "PNG"
                          },{
                            name: "JPG",
                            value: "JPG"
                          },{
                            name: "WEBP",
                            value: "WEBP"
                          },{
                            name: "PSD",
                            value: "PSD"
                          }]}
                          width="100%"
                          maxHeight={"200px"}
                          required
                        />
                      </OzCol>
                      {item.outputFormat && item.outputFormat.type ?
                        <>
                          {item.outputFormat.type.toUpperCase() !== "PSD" ?
                            <OzCol widthmd="6">
                              <OzInput
                                label="Min file size (byte)"
                                onChange={(val) => {
                                  let temp = {...item.outputFormat};
                                  temp.minFileSize = parseInt(val);
                                  let tempItem = {...item};
                                  tempItem.outputFormat = temp;
                                  setItem(tempItem);
                                }}
                                value={item.outputFormat && Number.isInteger(item.outputFormat.minFileSize) ? item.outputFormat.minFileSize : 0}
                                width="100%"
                              />
                            </OzCol>
                          : null}
                          {item.outputFormat.type.toUpperCase() !== "PSD" ?
                            <OzCol widthmd="6">
                              <OzInput
                                label="Max file size (byte)"
                                onChange={(val) => {
                                  let temp = {...item.outputFormat};
                                  temp.maxFileSize = parseInt(val);
                                  let tempItem = {...item};
                                  tempItem.outputFormat = temp;
                                  setItem(tempItem);
                                }}
                                value={item.outputFormat && Number.isInteger(item.outputFormat.maxFileSize) ? item.outputFormat.maxFileSize : 0}
                                width="100%"
                              />
                            </OzCol>
                          : null}
                          {item.outputFormat.type.toUpperCase() !== "PNG" && item.outputFormat.type.toUpperCase() !== "PSD" ?
                            <OzCol widthmd="6">
                              <OzInput
                                label="Quality"
                                onChange={(val) => {
                                  let temp = {...item.outputFormat};
                                  temp.quality = parseInt(val);
                                  let tempItem = {...item};
                                  tempItem.outputFormat = temp;
                                  setItem(tempItem);
                                }}
                                value={item.outputFormat && Number.isInteger(item.outputFormat.quality) ? item.outputFormat.quality : ""}
                                width="100%"
                              />
                            </OzCol>
                          : null}
                          <OzCol widthmd="6">
                            <OzInput
                              label="DPI"
                              onChange={(val) => {
                                let temp = {...item.outputFormat};
                                temp.dpi = parseInt(val);
                                let tempItem = {...item};
                                tempItem.outputFormat = temp;
                                setItem(tempItem);
                              }}
                              value={item.outputFormat && Number.isInteger(item.outputFormat.dpi) ? item.outputFormat.dpi : ""}
                              width="100%"
                            />
                          </OzCol>
                          {(item.outputFormat.type.toUpperCase() === "JPG" || item.outputFormat.type.toUpperCase() === "JPEG") ?
                            <OzCol widthmd="6">
                              <OzCheckbox
                                label={"Progressive"}
                                onChange={(val) => {
                                  let temp = {...item.outputFormat};
                                  temp.progressive = val;
                                  let tempItem = {...item};
                                  tempItem.outputFormat = temp;
                                  setItem(tempItem);
                                }}
                                selected={item.outputFormat && item.outputFormat.progressive ? item.outputFormat.progressive : false}
                              />
                            </OzCol>
                          : null}
                          {(item.outputFormat.type.toUpperCase() === "JPG" || item.outputFormat.type.toUpperCase() === "JPEG" || item.outputFormat.type.toUpperCase() === "PNG") ?
                            <OzCol widthmd="6">
                              <OzCheckbox
                                label={"Optimize"}
                                onChange={(val) => {
                                  let temp = {...item.outputFormat};
                                  temp.optimize = val;
                                  let tempItem = {...item};
                                  tempItem.outputFormat = temp;
                                  setItem(tempItem);
                                }}
                                selected={item.outputFormat && item.outputFormat.optimize !== null ? item.outputFormat.optimize : true}
                              />
                            </OzCol>
                          : null}
                          <OzCol widthmd="6">
                            <OzSelect
                              label="ICC profile"
                              onChange={(val) => {
                                let temp = {...item.outputFormat};
                                temp.iccProfile = val;
                                let tempItem = {...item};
                                tempItem.outputFormat = temp;
                                setItem(tempItem);
                              }}
                              selected={item.outputFormat && item.outputFormat.iccProfile ? item.outputFormat.iccProfile : "keep"}
                              values={[{
                                name: "Keep",
                                value: "keep"
                              },{
                                name: "None",
                                value: "none"
                              },{
                                name: "SRGB",
                                value: "srgb"
                              },{
                                name: "AdobeRGB1998",
                                value: "AdobeRGB1998"
                              }]}
                              width="100%"
                              maxHeight={"200px"}
                              required
                            />
                          </OzCol>
                        </>
                      : null}

                    </OzRow>
                    <OzRow>
                      <OzCol widthmd="12">
                        <br/><br/>
                        <ParamGroupTitle>Destination format metadata</ParamGroupTitle>
                      </OzCol>
                      <OzCol widthmd="12">
                        <OzCheckbox
                          label={"Keep image metadata"}
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            temp.imageMetadataKeep = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          selected={item.outputFormat && item.outputFormat.imageMetadataKeep ? item.outputFormat.imageMetadataKeep : false}
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="DocumentTitle"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.DocumentTitle = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.DocumentTitle ? item.outputFormat.imageMetadata.DocumentTitle : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Author (comma separated)"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.Author = val.split(",");
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Author ? item.outputFormat.imageMetadata.Author.join(",") : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="AuthorTitle"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.AuthorTitle = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.AuthorTitle ? item.outputFormat.imageMetadata.AuthorTitle : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Description"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.Description = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Description ? item.outputFormat.imageMetadata.Description : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="DescriptionWriter"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.DescriptionWriter = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.DescriptionWriter ? item.outputFormat.imageMetadata.DescriptionWriter : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Keywords (comma separated)"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.Keywords = val.split(",");
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Keywords ? item.outputFormat.imageMetadata.Keywords.join(",") : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="CopyrightNotice"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.CopyrightNotice = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.CopyrightNotice ? item.outputFormat.imageMetadata.CopyrightNotice : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="City"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.City = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.City ? item.outputFormat.imageMetadata.City : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="State"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.State = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.State ? item.outputFormat.imageMetadata.State : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Country"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.Country = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Country ? item.outputFormat.imageMetadata.Country : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="CreditLine"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.CreditLine = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.CreditLine ? item.outputFormat.imageMetadata.CreditLine : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Source"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.Source = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Source ? item.outputFormat.imageMetadata.Source : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Headline"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.Headline = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Headline ? item.outputFormat.imageMetadata.Headline : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Instructions"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.Instructions = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Instructions ? item.outputFormat.imageMetadata.Instructions : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="TransmissionReference"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            temp.imageMetadata.TransmissionReference = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.TransmissionReference ? item.outputFormat.imageMetadata.TransmissionReference : ""}
                          width="100%"
                        />
                      </OzCol>
                      <OzCol widthmd="6">
                        <OzInput
                          label="Urgency (1-8)"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            if(!temp.imageMetadata){
                              temp.imageMetadata = {};
                            }
                            if(val === "" || (parseInt(val) > 0 && parseInt(val) < 9)){
                              temp.imageMetadata.Urgency = parseInt(val);
                            }
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Urgency ? item.outputFormat.imageMetadata.Urgency : ""}
                          width="100%"
                        />
                      </OzCol>

                    </OzRow>

                    </Block>
                  </>
                : null}
              </>
            }
          </Container>
          <Footer>
            <Link to={"/insider/hotfolders/"+hotfolderUuid}><OzButton variant={"chetwodeblue-o"}>Back</OzButton></Link>
            
            <ErrorMessage noMargin>
              {errorMessage}
            </ErrorMessage>

            <OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>
          </Footer>
        </Wrapper>
      </HealthyWrapper>

    </>
  )
}

export default InsiderHotfoldersActionsSingleDetail;
