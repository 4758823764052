import React, {useRef, useState, useEffect, useCallback, useContext} from 'react';
import styled from 'styled-components';
import {CognitoContext} from '../../contexts/CognitoContext';

import {OzContainer, OzRow, OzCol, OzCard, OzTable, OzProjectTag, OzLoading, OzCopyable, OzButton, BITTERSWEET} from '@ozwol/webui';

import AccountService from '../../services/AccountService';
import ProjectsService from './../../services/ProjectsService';
import CoworkersService from './../../services/CoworkersService';
import ScriptsService from './../../services/ScriptsService';
import LogsService from './../../services/LogsService';

import {changeDate, getFormattedDate, getFormattedTime, convertSize} from './../../helpers';

import Filters from './../../components/Filters';
import Meta from './../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
// import Overlay from './../../components/Overlay';

import {formatError, checkPermission} from './../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2),
  & table tr td:nth-child(3),
  & table tr td:nth-child(5){
    word-break: break-all;
    white-space: unset;
  }
`;
const NoResult = styled.div`
  text-align: center;
`;
const LogCost = styled.div`
  text-align: center;
`;
const LogExecution = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  color: ${props => props.status === "ERROR" ? "#FF4848" : props.status === "WARNING" ? "#FFB100" : "#4AD384"};
  margin: 0 auto;
`;

const Dark = styled.div`;
  font-size: 12px;
`;
const Light = styled.div`
  color: #30303099;
  font-size: 12px;
`;
const Accordion = styled.div`
  display: flex;
  font-size: 12px;

  & table{
    width: 100%;
  }
  & table,
  & table *{
    border: 0 !important;
    background: transparent !important;
  }
`;
const AccordionLeft = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: ${props => props.full ? "100%" : "60%"};

  & *{
    white-space: normal;
  }
  & b{
    white-space: nowrap;
  }
`;
const AccordionRight = styled.div`
  color: ${BITTERSWEET};
  flex-grow: 0;
  flex-shrink: 0;
  width: 40%;
`;
const LogRow = styled.div`
  display: flex;
  padding-bottom: 3px;
  padding-left: 20px;
`;
const LogKey = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 150px;
  white-space: nowrap;
  font-weight: 600;
`;
const LogValue = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  white-space: normal; 
  width: calc(100% - 150px);
`;
const CardPhrase = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;
  position: relative;
  top: 2px;

  display: flex;
  // align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
  position: relative;
  top: -2px;
`;
const Filename = styled.div`

`;


const Logs = ({refScrollerPage}) => {
  let scrollRef = useRef(null);
  const [fetchError, setFetchError] = useState(false);
  const {cognito} = useContext(CognitoContext);
  const [list, setList] = useState(null);
  const [page, setPage] = useState(1);
  // const [listUsers, setListUsers] = useState([]);
  const [listAuthors, setListAuthors] = useState([]);
  const [filterSearchAuthors, setFilterSearchAuthors] = useState([]);
  const [listProjects, setListProjects] = useState([]);
  const [filterSearchProjects, setFilterSearchProjects] = useState([]);
  const [filterSearchScripts, setFilterSearchScripts] = useState([]);
  const [periodHistory, setPeriodHistory] = useState(null);
  // const [totPages, setTotPages] = useState(1);
  // const totPages = 1;
  const [listLists, setListLists] = useState([]);
  const [totResults, setTotResults] = useState(0);
  const DEFAULT_FILTERS = {
    "search": "",
    "project_uuid": null,
    "rangeDate": changeDate(new Date(), -6, "days", "startOfDay") + "|" + changeDate(new Date(), 0, "days", "endOfDay"),
    "result": null,
    "error_scope": null,
    "consumer_type": null,
    "origin": null,
    "script_uuid": null,
    "creditMin": "",
    "creditMax": "",
    "author_uuid": cognito.groups.includes("OzwolCoworker") ? cognito.ParsedJwtToken.sub : null,
    "destination_type": null
  };
  const [filters, setFilters] = useState(
    // localStorage.getItem("ozwolConsoleFilterOverallLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? 
    //   JSON.parse(localStorage.getItem("ozwolConsoleFilterOverallLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))))
    // : 
      DEFAULT_FILTERS
    );

  const [filtersSearch, setFiltersSearch] = useState({
    "script_uuid": ""
  });

  const changeFilters = useCallback((name, value) => {
    setList(null);
    setPage(0);
    let tempFilters = {...filters};
    if(name === "rangeDate"){
      let start = value.split('|')[0] !== "null" ? value.split('|')[0] : changeDate(new Date(), -6, "days", "startOfDay");
      let end = value.split('|')[1] !== "null" ? value.split('|')[1] : changeDate(new Date(), 0, "days", "endOfDay");
      value = start+"|"+end;
    }
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const searchFilters = useCallback((name, value) => {
    let tempFiltersSearch = {...filtersSearch};
    tempFiltersSearch[name] = value;
    setFiltersSearch(tempFiltersSearch);


    if(value.length > 2 || value.length === 0){
      switch(name){
        case "script_uuid":
          if(checkPermission("script", "list")){
            ScriptsService.list({
              "search": value.length > 2 ? value : ""
            }).then((response) => {
              setFilterSearchScripts(response.result);
            });
          }
          break;
        default:
          break;
      }
    }

  }, [filtersSearch]);

  const getList = useCallback(() => {
        
    setList(null);
    if(scrollRef && scrollRef.current){
      scrollRef.current.scrollTo(0,0);
    }
    LogsService.list(filters.search, {
      user_uuid: localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) : cognito.ownerUuid,
      project_uuid: filters.project_uuid,
      result: filters.result,
      error_scope: filters.error_scope,
      start: filters.rangeDate.split('|')[0],
      end: filters.rangeDate.split('|')[1],
      consumer_type: filters.consumer_type,
      origin: filters.origin,
      script_uuid: filters.script_uuid,
      creditMin: filters.creditMin,
      creditMax: filters.creditMax,
      author_uuid: filters.author_uuid,
      destination_type: filters.destination_type
    }).then((response) => {
      localStorage.setItem("ozwolConsoleFilterOverallLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(filters));
      setList(response.hits);
      setTotResults(response.found);
    }).catch((error) => {
      setList(null);
      setFetchError(formatError(error));
    });

  }, [filters, cognito]);

  const loadMore = useCallback(() => {
    LogsService.list(filters.search, {
      user_uuid: localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) : cognito.ownerUuid,
      project_uuid: filters.project_uuid,
      result: filters.result,
      error_scope: filters.error_scope,
      start: filters.rangeDate.split('|')[0],
      end: filters.rangeDate.split('|')[1],
      consumer_type: filters.consumer_type,
      origin: filters.origin,
      script_uuid: filters.script_uuid,
      creditMin: filters.creditMin,
      creditMax: filters.creditMax,
      author_uuid: filters.author_uuid,
      destination_type: filters.destination_type
    }, page+1).then((response) => {
      let tempList = [...list, ...response.hits];
      setList(tempList);
      setPage(page+1);
    }).catch((error) => {
      // setList(null);
      // setFetchError(formatError(error));
    });

  }, [list, page, filters, cognito]);

  useEffect(() => {
    if(cognito !== null){
      getList();
    }
  }, [getList, filters, cognito]);

  useEffect(() => {
    ProjectsService.list().then((response) => {
      setFilterSearchProjects(response);
      setListProjects(response);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    CoworkersService.list().then((response) => {
      setListAuthors(response.result);
      setFilterSearchAuthors(response.result);
    });
    if(checkPermission("script", "list")){
      ScriptsService.list().then((response) => {
        setFilterSearchScripts(response.result);
      }).catch((error) => {
        setFetchError(formatError(error));
      }); 
    }
    LogsService.listLists().then((response) => {
      setListLists(response.result);
    }).catch((error) => {
      setListLists(null);
      setFetchError(formatError(error));
    });

    if(checkPermission("period", "list")){
      AccountService.listPeriods().then((response) => {
        if(response && response.result){
          setPeriodHistory(response.result.slice(0, 6).reverse());
        }
      }).catch((error) => {
        setPeriodHistory(null);
        setFetchError(formatError(error));
      });
    }
  }, []);

  return (
    <div ref={scrollRef}>
      <OzContainer size="extra">
        <Meta title={"Overall - Logs"} />
        <PageHeader
          breadcrumbsIcon="checklist_rtl"
          breadcrumbsText={["Logs", "Overall"]}
          refScrollerPage={refScrollerPage}
        />
        
        <OzRow>
          <OzCol>
            <HealthyWrapper error={fetchError}>
              <OzCard                
                headerLeft={
                  <CardHeaderTitle title={"Logs"} />
                }
                headerSeparator={true}
                headerRight={
                  <CardPhrase>
                    <CardPhraseText>
                    Total results
                      <CardPhraseSuperNumber>
                        {totResults}
                      </CardPhraseSuperNumber>
                    </CardPhraseText>
                  </CardPhrase>
                }
              >
                <OzRow>
                  <OzCol widthmd="12">
                    <Filters
                      onChange={(...args) => changeFilters(...args)}
                      onSearch={(...args) => searchFilters(...args)}
                      fields={
                        [
                          {
                            "label": "Project",
                            "name": "project_uuid",
                            "size": 4,
                            "type": "searchableselect",
                            "placeholder": null,
                            "value": filters.project_uuid,
                            "options": [{
                              "name": <i>All</i>,
                              "value": null,
                            }].concat(filterSearchProjects.map((item) => ({
                              "name": <OzProjectTag
                                  name={item.name}
                                  uuid={item.uuid}
                                  color={item.color}
                                  direction="horizontal"
                                />,
                              "value": item.uuid,
                            })))
                          },{
                            "label": "Search",
                            "name": "search",
                            "size": 3,
                            "type": "text",
                            "placeholder": null,
                            "value": filters.search
                          }, 
                          checkPermission("script", "list") ? 
                            {
                              "label": "Script",
                              "name": "script_uuid",
                              "size": 3,
                              "type": "searchableselect",
                              "placeholder": null,
                              "value": filters.script_uuid,
                              "options": [{
                                "name": <i>All</i>,
                                "value": null,
                              }].concat(filterSearchScripts.map((item) => ({
                                "name": item.name,
                                "value": item.uuid,
                              })))
                            }
                          : null,
                          {
                            "label": "Consumer type",
                            "name": "consumer_type",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.consumer_type,
                            "options": [
                              {
                                "name": "All logs",
                                "value": null
                              }
                            ].concat(listLists && listLists["ConsumerType"] ? listLists["ConsumerType"].map((item) => ({
                              "name": item,
                              "value": item,
                            })): [])
                          }, checkPermission("period", "list") ?
                            {
                              "label": "Date",
                              "name": "rangeDate",
                              "size": 1,
                              "type": "rangedate",
                              "placeholder": null,
                              "value": filters.rangeDate,
                              "periods": periodHistory ? periodHistory.map((val, i) => {return {
                                "label": (i === periodHistory.length -1 ?
                                  "Current"
                                :
                                  getFormattedDate(val.startDate, false) + " - " + getFormattedDate(val.endDate, false)
                                ),
                                "start": val.startDate, 
                                "end": val.endDate
                              }}) : null
                        
                            }
                          : null,
                          {
                            "label": "Level",
                            "name": "result",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.result,
                            "options": [
                              {
                                "name": "All logs",
                                "value": null
                              }
                            ].concat(listLists && listLists["ScriptResult"] ? listLists["ScriptResult"].map((item) => ({
                              "name": item,
                              "value": item,
                            })): [])
                          },{
                            "label": "Error Scope",
                            "name": "error_scope",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.error_scope,
                            "options": [
                              {
                                "name": "All logs",
                                "value": null
                              }
                            ].concat(listLists && listLists["ErrorScope"] ? listLists["ErrorScope"].map((item) => ({
                              "name": item,
                              "value": item,
                            })): [])
                          },{
                            "label": "Consumer type",
                            "name": "consumer_type",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.consumer_type,
                            "options": [
                              {
                                "name": "All logs",
                                "value": null
                              }
                            ].concat(listLists && listLists["ConsumerType"] ? listLists["ConsumerType"].map((item) => ({
                              "name": item,
                              "value": item,
                            })): [])
                          },{
                            "label": "Origin",
                            "name": "origin",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.origin,
                            "options": [
                              {
                                "name": "All logs",
                                "value": null
                              }
                            ].concat(listLists && listLists["ScriptOrigin"] ? listLists["ScriptOrigin"].map((item) => ({
                              "name": item,
                              "value": item,
                            })): [])
                          },{
                            "label": "Dest. type",
                            "name": "destination_type",
                            "size": 2,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.destination_type,
                            "options": [
                              {
                                "name": "All logs",
                                "value": null
                              }
                            ].concat(listLists && listLists["DestinationType"] ? listLists["DestinationType"].map((item) => ({
                              "name": item,
                              "value": item
                            })): [])
                          },{
                            "label": "Credit min",
                            "name": "creditMin",
                            "size": 2,
                            "type": "text",
                            "placeholder": null,
                            "value": filters.creditMin
                          },{
                            "label": "Credit max",
                            "name": "creditMax",
                            "size": 2,
                            "type": "text",
                            "placeholder": null,
                            "value": filters.creditMax
                          }, !cognito.groups.includes("OzwolCoworker") ? {
                            "label": "Author",
                            "name": "author_uuid",
                            "size": 4,
                            "type": "searchableselect",
                            "placeholder": null,
                            "value": filters.author_uuid,
                            "options": [{
                              "name": <i>All</i>,
                              "value": null,
                            }].concat({
                              "name": cognito.ParsedJwtToken.given_name + " " + cognito.ParsedJwtToken.family_name,
                              "value": cognito.ParsedJwtToken.sub,
                            }).concat(filterSearchAuthors.map((item) => ({
                              "name": item.givenName + " " + item.familyName,
                              "value": item.uuid,
                            })))
                          } : null,{
                            "size": 2,
                            "type": "refresh",
                            "callback": () => {
                              getList();
                            }
                          },{
                            "size": 2,
                            "type": "clear",
                            "callback": () => {
                              setFilters(DEFAULT_FILTERS);
                              // localStorage.removeItem("ozwolConsoleFilterOverallLogs"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))
                            }
                          }
                        ]
                      }
                    />
                    <hr/>
                    {list ?
                      list.length > 0 ?
                        <>
                          <TableWrapper>
                              <OzTable
                                columns={[
                                  "Project",
                                  "Date",
                                  "Time",
                                  "Author",
                                  "File name",
                                  "Activity",
                                  "Size",
                                  <center>Cost</center>,
                                  <center>Exec</center>
                                ]}
                                values={
                                  list.filter(item => listProjects.map(proj => proj.uuid).includes(item.document.project_uuid) || item.document.project_uuid === null).map(item => ({
                                    style: {
                                      color: item.result === "ERROR" ? "#FF4848" : null
                                    },
                                    id: item.document.id,
                                    accordion: <Accordion>
                                      <AccordionLeft full={item.document.result !== "ERROR" && item.document.result !== "WARNING"}>
                                        <table>
                                          {item.document.assets_processed ? 
                                            <LogRow>
                                              <LogKey>Assets processed</LogKey>
                                              <LogValue>{item.document.assets_processed}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.author_uuid && !cognito.groups.includes("OzwolCoworker") ? 
                                            <>
                                              <LogRow>
                                                <LogKey>Author</LogKey>
                                                <LogValue>
                                                  { cognito.ParsedJwtToken.sub === item.document.author_uuid ? 
                                                    cognito.ParsedJwtToken.given_name + " " + cognito.ParsedJwtToken.family_name
                                                  : listAuthors.filter(val => val.uuid === item.document.author_uuid).length > 0 ?
                                                    listAuthors.filter(val => val.uuid === item.document.author_uuid).map(author =>
                                                      author.givenName + " " + author.familyName
                                                    )
                                                  : "-"}
                                                </LogValue>
                                              </LogRow>
                                              <LogRow>
                                                <LogKey>Author UUID</LogKey>
                                                <LogValue>{item.document.author_uuid}</LogValue>
                                              </LogRow>
                                            </>
                                          : null}
                                          {item.document.consumer_type ? 
                                            <LogRow>
                                              <LogKey>Consumer type</LogKey>
                                              <LogValue>{item.document.consumer_type}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.credit_price ? 
                                            <LogRow>
                                              <LogKey>Credit price</LogKey>
                                              <LogValue>{item.document.credit_price}</LogValue>
                                            </LogRow>
                                          : null}
                                          <LogRow>
                                            <LogKey>Date</LogKey>
                                            <LogValue>{getFormattedDate(item.document.create_datetime)}</LogValue>
                                          </LogRow>
                                          {item.document.destination_file_key ? 
                                            <LogRow>
                                              <LogKey>Destination file name</LogKey>
                                              <LogValue>
                                                <OzCopyable>{item.document.destination_file_key.substring(item.document.destination_file_key.lastIndexOf('/')+1)}</OzCopyable>
                                              </LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.destination_file_size ? 
                                            <LogRow>
                                              <LogKey>Destination file size</LogKey>
                                              <LogValue>{convertSize(item.document.destination_file_size)}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.destination_type ? 
                                            <LogRow>
                                              <LogKey>Destination type</LogKey>
                                              <LogValue>{item.document.destination_type}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.job_uuid ? 
                                            <LogRow>
                                              <LogKey>Job UUID</LogKey>
                                              <LogValue>
                                                <OzCopyable>{item.document.job_uuid}</OzCopyable>
                                              </LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.processing_time_ms ? 
                                            <LogRow>
                                              <LogKey>Process time (ms)</LogKey>
                                              <LogValue>{item.document.processing_time_ms}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.project_uuid ? 
                                            <LogRow>
                                              <LogKey>Project</LogKey>
                                              <LogValue> 
                                                {listProjects.filter(val => val.uuid === item.document.project_uuid).length > 0 ?
                                                  listProjects.filter(val => val.uuid === item.document.project_uuid).map(project =>
                                                    project.name
                                                  )
                                                : "-"}
                                              </LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.project_uuid ? 
                                            <LogRow>
                                              <LogKey>Project UUID</LogKey>
                                              <LogValue> 
                                                <OzCopyable>{item.document.project_uuid}</OzCopyable>
                                              </LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.response_size ? 
                                            <LogRow>
                                              <LogKey>Response size</LogKey>
                                              <LogValue>{convertSize(item.document.response_size)}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.script_name ? 
                                            <LogRow>
                                              <LogKey>Script</LogKey>
                                              <LogValue>{item.document.script_name}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.source_file_key ? 
                                            <LogRow>
                                              <LogKey>Source file name</LogKey>
                                              <LogValue>
                                                <OzCopyable>{item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)}</OzCopyable>
                                              </LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.source_file_size ? 
                                            <LogRow>
                                              <LogKey>Source file size</LogKey>
                                              <LogValue>{convertSize(item.document.source_file_size)}</LogValue>
                                            </LogRow>
                                          : null}
                                          {item.document.storage_bytes ? 
                                            <LogRow>
                                              <LogKey>Storage bytes</LogKey>
                                              <LogValue>{convertSize(item.document.storage_bytes)}</LogValue>
                                            </LogRow>
                                          : null}
                                          <LogRow>
                                            <LogKey>Time</LogKey>
                                            <LogValue>{getFormattedTime(item.document.create_datetime)}</LogValue>
                                          </LogRow>
                                          {item.document.transfer_bytes ? 
                                            <LogRow>
                                              <LogKey>Transfer bytes</LogKey>
                                              <LogValue>{convertSize(item.document.transfer_bytes)}</LogValue>
                                            </LogRow>
                                          : null}
                                        </table>
                                      </AccordionLeft>
                                      {item.document.result === "ERROR" || item.document.result === "WARNING" ?
                                        <AccordionRight>
                                        
                                          <table>
                                            {item.document.error_code ? 
                                              <LogRow>
                                                <LogKey>Error</LogKey>
                                                <LogValue>{item.document.error_code}</LogValue>
                                              </LogRow>
                                            : null}
                                            {item.document.error_description ? 
                                              <LogRow>
                                                <LogKey>Description</LogKey>
                                                <LogValue>{item.document.error_description}</LogValue>
                                              </LogRow>
                                            : null}
                                            {item.document.error_scope ? 
                                              <LogRow>
                                                <LogKey>Type</LogKey>
                                                <LogValue>{item.document.error_scope}</LogValue>
                                              </LogRow>
                                            : null}
                                          </table>
                                        </AccordionRight>
                                      : null}
                                    </Accordion>,
                                    values: [
                                      listProjects.filter(val => val.uuid === item.document.project_uuid).length > 0 ?
                                        listProjects.filter(val => val.uuid === item.document.project_uuid).map(project =>
                                          <OzProjectTag
                                            direction="horizontal"
                                            key={project.uuid}
                                            name={project.name}
                                            // uuid={project.uuid}
                                            color={project.color}

                                          />
                                        )
                                      : "-",
                                      <Dark>
                                        {getFormattedDate(item.document.create_datetime)}
                                      </Dark>,
                                      <Light>
                                        {getFormattedTime(item.document.create_datetime)}
                                      </Light>,
                                      <Dark>
                                        {item.document.author_uuid && !cognito.groups.includes("OzwolCoworker") ? 
                                          (
                                            cognito.ParsedJwtToken.sub === item.document.author_uuid ? 
                                              cognito.ParsedJwtToken.given_name + " " + cognito.ParsedJwtToken.family_name
                                            : listAuthors.filter(val => val.uuid === item.document.author_uuid).length > 0 ?
                                              listAuthors.filter(val => val.uuid === item.document.author_uuid).map(author =>
                                                author.givenName + " " + author.familyName
                                              )
                                            : "-"
                                          )
                                        : cognito.ParsedJwtToken.given_name + " " + cognito.ParsedJwtToken.family_name}
                                      </Dark>,
                                      <Dark>
                                        {item.document.source_file_key ? 
                                          <Filename title={item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)}>
                                            <div onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                              <OzCopyable text={item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)}>
                                                {((item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1))
                                                .length > 20 ? 
                                                  item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)
                                                  .slice(0, 17)
                                                  +"..."
                                                :
                                                  item.document.source_file_key.substring(item.document.source_file_key.lastIndexOf('/')+1)
                                                )}
                                              </OzCopyable>
                                            </div>
                                          </Filename>
                                        : "-"}
                                      </Dark>,
                                      <Dark>
                                        {item.document.consumer_type === "SCRIPT" ? 
                                          item.document.script_name
                                        :
                                          item.document.consumer_type
                                        }
                                      </Dark>,
                                      item.document.consumer_type === "STORAGE" ?
                                        convertSize(item.document.storage_in_use)
                                      : item.document.consumer_type === "TRANSFER" ?
                                        convertSize(item.document.transfer_consumed)
                                      :
                                        convertSize(item.document.source_file_size),
                                      <LogCost>{item.document.credit_price}</LogCost>,
                                      <LogExecution status={item.document.result}>
                                        <span className="material-symbols-outlined">{
                                          item.document.result === "SUCCESS" ? 
                                            "done"
                                          : item.document.result === "WARNING" ? 
                                            "warning"
                                          : item.document.result === "ERROR" ? 
                                            "close"
                                          : item.document.result === "ONGOING" ? 
                                            "hourglass_empty"
                                          : null
                                        }</span>
                                      </LogExecution>
                                    ]
                                  }))
                                }
                              />
                            </TableWrapper>
                            {totResults > list.length ? 
                              <>
                                <br/><br/>
                                <center>
                                  <OzButton variant="push-black" onClick={() => loadMore()}>The previous 100...</OzButton>
                                </center>
                              </>
                            : null}

                        </>
                      : <NoResult>There are no results.</NoResult>
                    : <OzLoading />}
                  </OzCol>
                </OzRow>
                {/* {totPages > 1 ?
                  <OzPagination totPages={totPages} currentPage={page} onChange={((val) => setPage(val))} />
                : null } */}
              </OzCard>
            </HealthyWrapper>
          </OzCol>
        </OzRow>
      </OzContainer>
    </div>
  )
}

export default Logs;
