import React, {useState} from 'react';
import styled from 'styled-components';
import { Sketch } from '@uiw/react-color';

import { BORDER, CHETWODEBLUE } from '@ozwol/webui';

const Wrapper = styled.div`
  text-align: left;

  &, & * {
    box-sizing: border-box;
  }

  .w-color-sketch {
    --sketch-background: ${props => props.theme === "dark" ? "#323232" : "#ffffff"} !important;
  }

  .w-color-swatch {
    --sketch-swatch-border-top: 1px solid ${props => props.theme === "dark" ? "#525252" : BORDER} !important;
  }

  .w-color-block {
    --block-background-color: #323232 !important;
    --block-box-shadow: rgb(0 0 0 / 10%) 0 1px !important;
  }

  .w-color-editable-input {
    --editable-input-label-color: #757575 !important;
    --editable-input-box-shadow: #616161 0px 0px 0px 1px inset !important;
    --editable-input-color: ${props => props.theme === "dark" ? "#bbb" : "#000000"} !important;
  }

  .w-color-github {
    --github-border: 1px solid rgba(0, 0, 0, 0.2) !important;
    --github-background-color: #323232 !important;
    --github-box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px !important;
    --github-arrow-border-color: rgba(0, 0, 0, 0.15) !important;
  }

  .w-color-compact {
    --compact-background-color: #323232 !important;
  }

  .w-color-material {
    --material-background-color: #323232 !important;
    --material-border-bottom-color: #707070 !important;
  }

  .w-color-alpha {
    --alpha-pointer-background-color: #6a6a6a !important;
    --alpha-pointer-box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px !important;
  }
`;
const Container = styled.div`
  position: relative;
`;
const Active = styled.div`
  width: ${props => props.height};
  height: ${props => props.height};
  border-radius: 40px;
  border: 1px solid ${props => props.highlighted ? CHETWODEBLUE : BORDER};
  box-shadow: inset 0px 1px 2px #687FE262;
  cursor: pointer;
  background-color: ${props => props.color};

  &:hover{
    // border: 2px solid #FFFFFF;
  }
`;


const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: 300;
`;
const Picker = styled.div`
  position: absolute;
  top: -15px;
  left: 53px;
  z-index: 99999;
`;
const ClickCatcher = styled.div`
  display: ${props => props.open ? "block" : "none"};
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99998;
`;


const ColorPicker = ({
  label,
  value = "transparent",
  height = "34px",
  required = false,
  highlighted = false,
  theme = "light",
  onChange
}) => {
  // const {addOverlay} = useContext(OverlayContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper theme={theme}>
      {label ?
        <Label>
          {label} {required ? "*" : ""}
        </Label>
      : null}
      <Container>
        <Active highlighted={highlighted} height={height} color={value} onClick={() => setIsOpen(true)} />
        {isOpen ?
          <Picker>
            <Sketch 
              color={value}
              onChange={(color) => {
                onChange(color.hex);
                // removeOverlay("colorpicker");
              }}
            />
          </Picker>
        : null }
      </Container>
      <ClickCatcher open={isOpen} onClick={() => setIsOpen(false)} />
    </Wrapper>

  );
}

export default ColorPicker;
