import React, {useState, useContext} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../contexts/OverlayContext';

import {OzButton, OzCard, OzInput, OzSelect} from '@ozwol/webui';

import CardHeaderTitle from '../components/CardHeaderTitle';

// import {formatError} from '../helpers';

const Container = styled.div`
  width: 450px;
`;


const ModalRuler = ({ruler = null, i=null, onSuccess, onRemove}) => {
  const {removeOverlay} = useContext(OverlayContext);
  const [from, setFrom] = useState(ruler ? ruler.from : "top");
  const [value, setValue] = useState(ruler ? ruler.value : 0);

  return (
    <Container>

      <OzCard
        headerLeft={
          <CardHeaderTitle 
            title={"Edit ruler"} 
          />
        }
        headerSeparator={true}
        footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("ruler")}>Cancel</OzButton>}
        footerRight={<>
          {i !== null ? 
            <OzButton variant={"bittersweet-o"} onClick={() => {
              onRemove(i)
              removeOverlay("ruler");
            }}>Remove</OzButton>
          : null}
          &nbsp;&nbsp;
          <OzButton variant={"chetwodeblue-o"} onClick={() => {
            onSuccess({
              "from": from,
              "value": value
            })
            removeOverlay("ruler");
          }}>Ok</OzButton>
        </>}
      >
        <OzSelect
          label="Origin"
          width="100%"
          values={[
            {
              "name": "From top",
              "value": "top"
            },
            {
              "name": "From right",
              "value": "right"
            },
            {
              "name": "From bottom",
              "value": "bottom"
            },
            {
              "name": "From left",
              "value": "left"
            }
          ]}
          selected={from}
          onChange={(val) => {
            setFrom(val);
          }}
          maxHeight={"200px"}
          required
        />
        <OzInput
          label="Value (pixel)"s
          width="100%"
          value={value}
          onChange={(val) => {
            setValue(val);
          }}
          required
        />

      </OzCard>

    </Container>
  );

}

export default ModalRuler;
