import React, {useState, useEffect} from 'react';

const SessionContext = React.createContext();

const SessionContextProvider = ({children}) => {
  const [currentProject, setCurrentProject] = useState(localStorage.getItem("ozwolConsoleDefaultProject"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? localStorage.getItem("ozwolConsoleDefaultProject"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) : null);
  
  const [currentPreviewSettingsShowZoom, setCurrentPreviewSettingsShowZoom] = useState(localStorage.getItem("ozwolConsolePreviewSettingsShowZoom"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? JSON.parse(localStorage.getItem("ozwolConsolePreviewSettingsShowZoom"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))) : false);
  const [currentPreviewSettingsShowRulers, setCurrentPreviewSettingsShowRulers] = useState(localStorage.getItem("ozwolConsolePreviewSettingsShowRulers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? JSON.parse(localStorage.getItem("ozwolConsolePreviewSettingsShowRulers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))) : false);
  const [currentPreviewSettingsRulers, setCurrentPreviewSettingsRulers] = useState(localStorage.getItem("ozwolConsolePreviewSettingsRulers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? JSON.parse(localStorage.getItem("ozwolConsolePreviewSettingsRulers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))) : []);
  const [currentPreviewSettingsShowTransparency, setCurrentPreviewSettingsShowTransparency] = useState(localStorage.getItem("ozwolConsolePreviewSettingsShowTransparency"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? JSON.parse(localStorage.getItem("ozwolConsolePreviewSettingsShowTransparency"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))) : true);
  const [currentPreviewSettingsBgcolor, setCurrentPreviewSettingsBgcolor] = useState(localStorage.getItem("ozwolConsolePreviewSettingsBgcolor"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? localStorage.getItem("ozwolConsolePreviewSettingsBgcolor"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) : "#4A4A4A");

  useEffect(() => {
    localStorage.setItem("ozwolConsoleDefaultProject"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), currentProject);
  }, [currentProject])

  useEffect(() => {
    localStorage.setItem("ozwolConsolePreviewSettingsShowZoom"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(currentPreviewSettingsShowZoom));
  }, [currentPreviewSettingsShowZoom])
  useEffect(() => {
    localStorage.setItem("ozwolConsolePreviewSettingsShowRulers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(currentPreviewSettingsShowRulers));
  }, [currentPreviewSettingsShowRulers])
  useEffect(() => {
    localStorage.setItem("ozwolConsolePreviewSettingsRulers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(currentPreviewSettingsRulers));
  }, [currentPreviewSettingsRulers])
  useEffect(() => {
    localStorage.setItem("ozwolConsolePreviewSettingsShowTransparency"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(currentPreviewSettingsShowTransparency));
  }, [currentPreviewSettingsShowTransparency])
  useEffect(() => {
    localStorage.setItem("ozwolConsolePreviewSettingsBgcolor"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), currentPreviewSettingsBgcolor);
  }, [currentPreviewSettingsBgcolor])


  return (
    <SessionContext.Provider value={{
      currentProject, setCurrentProject,
      currentPreviewSettingsShowZoom, setCurrentPreviewSettingsShowZoom,
      currentPreviewSettingsShowRulers, setCurrentPreviewSettingsShowRulers,
      currentPreviewSettingsRulers, setCurrentPreviewSettingsRulers,
      currentPreviewSettingsShowTransparency, setCurrentPreviewSettingsShowTransparency,
      currentPreviewSettingsBgcolor, setCurrentPreviewSettingsBgcolor
    }}>
      {children}
    </SessionContext.Provider>
  )
}

export {SessionContextProvider, SessionContext}
