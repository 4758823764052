
import React, {useState, useContext, useEffect} from 'react';
import { useImageSize } from 'react-image-size';
import { TransformWrapper, TransformComponent, MiniMap } from "react-zoom-pan-pinch";
// import {useParams} from 'react-router-dom';
// import axios from 'axios';
import styled from 'styled-components';
import {OverlayContext} from './../../../contexts/OverlayContext';
import {SessionContext} from './../../../contexts/SessionContext';

import { SILVERTREE, OzButton } from '@ozwol/webui'; //CHETWODEBLUE

import OverlayFullscreen from './../../../components/OverlayFullscreen';
import ColorPicker from '../../../components/ColorPicker';
import ModalRuler from './../../../chunks/ModalRuler';

// import ReactImageZoom from 'react-image-zoom';

// import InnerImageZoom from 'react-inner-image-zoom';
// import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  // color: ${props => props.isActive ? SILVERTREE : "#ffffff"};
  opacity: ${props => props.isActive ? 1 : 0.33};
  flex-grow: 0;
  flex-shrink: 0;

  cursor: pointer;
  width: 64px;
  height: 64px;

  &:hover{
    opacity: 1;
  }
`;
const ItemIcon = styled.div`
  margin-bottom: -3px;
  & *{  
    font-size: 30px;
  }
`;

const ItemPicker = styled.div`
  margin: 3px;
`;
const ItemText = styled.div`
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.3px
  position: relative;
  height: 10px;
  line-height: 10px;
`;

const Counter = styled.div`
  color: #ffffff;
  width: 100%;
  text-align: center;
`;
const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;  
`;
const CurrentPath = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
`;

const CurrentPathIcon = styled.div`
  width: 26px;
  height: 26px;
  margin-right: -3px;
  color: ${"#ffffff"}; //CHETWODEBLUE
  margin-bottom: -1px;

  & span{
    display: block;
  }

`;

const FileName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0px 10px;
`;
const DownloadButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0px 10px;
`;
const FileInfo = styled.div`
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 10px;
`;



const MacroContent = styled.div`  
  position: relative;
  width: 100%;
  height: 100%;
`;
const RulersContent = styled.div`  
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: calc(100% - ${props => props.showRulers ? 15 : 0}px);
  height: calc(100% - ${props => props.showRulers ? 15 : 0}px);
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
`;

const MinimapWrapper = styled.div`
  border: 1px solid #D5D9ED54;
  position: absolute;
  top: 5px;
  right: 5px;

  & .rzpp-preview {
    border-color: ${SILVERTREE} !important;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display:inline-block;
  max-width: 100%;
  max-height: 100%;
    overflow: visible;

  ${props => props.showZoom ? `
    cursor: zoom-in;
  `: null }
  & img {
    background-color: ${props => props.bgcolor};
  }

  ${props => props.showTransparency ? `
    & img {
      background-size: 20px 20px;
      background-image: 
        linear-gradient(45deg, transparent 25%, transparent 25%, 
                        transparent 75%, transparent 75%, transparent),
        linear-gradient(45deg, transparent 25%, transparent 25%, 
                        transparent 75%, transparent 75%, transparent),
        linear-gradient(45deg, #FFFFFF40 25%, transparent 25%, 
                        transparent 75%, #FFFFFF40 75%, #FFFFFF40),
        linear-gradient(45deg, #FFFFFF40 25%, transparent 25%, 
                        transparent 75%, #FFFFFF40 75%, #FFFFFF40);
            background-position: 0 0, 10px 10px, 10px 0, 0 10px;
    }
  `: null }
  
  & .react-transform-wrapper{
    display: block;
    overflow: visible;
  }
  

`;
const Image = styled.img`
  max-width: calc(calc(100vw - 128px) - ${props => props.showRulers ? 15 : 0}px);
  max-height: calc(calc(100vh - 128px) - ${props => props.showRulers ? 15 : 0}px);
  width: auto;
  height: auto;
  display: block;
`;

const Arrow = styled.div`
  position: absolute;
  z-index: 999;
  top: calc(50% - 13px);
  ${props => props.prev ? "left" : "right"}: 10px;
  opacity: 0.33;
  &:hover{
    opacity: 1;
  }
  width: 26px;
  height: 26px;
  color: #fff;
  cursor: pointer;

  & span{
    display: block;
  }
`;


const HSeparator = styled.div`
  border-top: 1px solid #D5D9ED54;
  width: 100%;
  height: 1px;
`;
const VSeparator = styled.div`
  border-left: 1px solid #D5D9ED54;
  width: 1px;
  height: 100%;
`;

const ButtonIcon = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px;
  & > span{
    font-size: 20px;
  }
`;

const VerticalRuler = styled.div`
  position: absolute;
  top: 0px;
  left: -15px;
  width: 15px;
  height: 100%;
  background: #ffffff11;
  cursor: grabbing;

  background-repeat: repeat-y;
  background-image:
    linear-gradient(0deg, #ffffff33 0 1px, transparent 0),
    linear-gradient(0deg, #ffffff 0 1px, transparent 0);
  background-size:
    8px calc(calc(100% * 20) / ${props => props.max}),
    15px calc(calc(100% * 100) / ${props => props.max});

`;
const HorizontalRuler = styled.div`
  position: absolute;
  top: -15px;
  left: 0px;
  width: 100%;
  height: 15px;
  background: #ffffff11;
  cursor: grabbing;

  background-repeat: repeat-x;
  background-image:
    linear-gradient(270deg, #ffffff33 0 1px, transparent 0),
    linear-gradient(270deg, #ffffff 0 1px, transparent 0);
  background-size:
    calc(calc(100% * 20) / ${props => props.max}) 8px,
    calc(calc(100% * 100) / ${props => props.max}) 15px;
`;
const Ruler = styled.div`
  cursor: grabbing;
  box-sizing: content-box;
  position: absolute;
  background-color: ${SILVERTREE};
  background-clip: padding-box;
  ${props => props.from}: calc(calc(calc(100% * ${props => props.value}) / ${props => props.max}) - 6px);
  ${props => props.direction === "horizontal" ?  `
    left: 0px;
    width: 100%;
    height: 1px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  ` : `
    top: 0px;
    width: 1px;
    height: 100%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  `}
    
  &::before{ //freccia
    width: 0; 
    height: 0; 
    position: relative;
    border: 8px solid transparent;
    ${props => props.direction === "horizontal" ?  `
      left: 85%;
    ` : `
      top: 90%;
    `}
    ${props => props.from === "top" ?  `
      top: -16px;
      border-bottom: 8px solid ` + SILVERTREE + `;
    ` : props.from === "right" ?  ` 
      right: -1px;    
      border-left: 8px solid ` + SILVERTREE + `;
    ` : props.from === "bottom" ?  ` 
      bottom: -1px;
      border-top: 8px solid ` + SILVERTREE + `;
    ` : props.from === "left" ?  ` 
      left: -16px;    
      border-right: 8px solid ` + SILVERTREE + `;
    ` : null}

    display: inline-block;
    content: "";
  }

  &::after{ //scritta
    width: 50px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    position: relative;
    ${props => props.direction === "horizontal" ?  `
      left: calc(calc(85% - 25px) - 8px);
    ` : `
      top: calc(calc(90% - 8px) - 14px);
    `}
    ${props => props.from === "top" ?  `
      top: -28px;
    ` : props.from === "right" ?  `
      right: -12px;    
      text-align: left;
    ` : props.from === "bottom" ?  ` 
      bottom: -5px;
    ` : props.from === "left" ?  ` 
      left: -62px;    
      text-align: right;
    ` : null}

    display: inline-block;
    content: "${props => props.value}";
    color: ${SILVERTREE};
    font-weight: bold;
  }
  
`;



const OverlayPreview = ({
  downloadUrl, 
  filePath, 
  // folderPath, 
  allFilesPaths,
  onNav,
  getFiles
}) => {
    const {addOverlay, removeOverlay} = useContext(OverlayContext);
    const {
      currentPreviewSettingsShowZoom, setCurrentPreviewSettingsShowZoom,
      currentPreviewSettingsShowRulers, setCurrentPreviewSettingsShowRulers,
      currentPreviewSettingsRulers, setCurrentPreviewSettingsRulers,
      currentPreviewSettingsShowTransparency, setCurrentPreviewSettingsShowTransparency,
      currentPreviewSettingsBgcolor, setCurrentPreviewSettingsBgcolor
    } = useContext(SessionContext);
    const [dimensions] = useImageSize(downloadUrl);
    // const [currentRuler, setCurrentRuler] = useState([]);

    /*settings*/
    const [showZoom, setShowZoom] = useState(currentPreviewSettingsShowZoom);
    const [showRulers, setShowRulers] = useState(currentPreviewSettingsShowRulers);
    const [rulers, setRulers] = useState(currentPreviewSettingsRulers);
    const [showTransparency, setShowTransparency] = useState(currentPreviewSettingsShowTransparency);
    const [bgcolor, setBgcolor] = useState(currentPreviewSettingsBgcolor);


    useEffect(() => {
      setCurrentPreviewSettingsShowZoom(showZoom);
    }, [showZoom, setCurrentPreviewSettingsShowZoom])
    useEffect(() => {
      setCurrentPreviewSettingsShowRulers(showRulers);
    }, [showRulers, setCurrentPreviewSettingsShowRulers])
    useEffect(() => {
      setCurrentPreviewSettingsRulers(rulers);
    }, [rulers, setCurrentPreviewSettingsRulers])
    useEffect(() => {
      setCurrentPreviewSettingsShowTransparency(showTransparency);
    }, [showTransparency, setCurrentPreviewSettingsShowTransparency])
    useEffect(() => {
      setCurrentPreviewSettingsBgcolor(bgcolor);
    }, [bgcolor, setCurrentPreviewSettingsBgcolor])

    const addRuler = (ruler = null, i = null) => {
      addOverlay({
        reference: "ruler",
        isCloseable: false,
        content: <ModalRuler 
          ruler={ruler} 
          i={i} 
          onSuccess={(val) => {
            let tempRulers = [...rulers];
            if(i){
              tempRulers[i] = val;
            }else{
              tempRulers.push(val);
            }
            setRulers(tempRulers);
          }}
          onRemove={(i) => {
            let tempRulers = [...rulers];
            tempRulers = tempRulers.filter((item, index) => index !== i);
            setRulers(tempRulers);
          }}
        />
      });
    };

    return (
      <OverlayFullscreen 
        mainSidebar={<Bar>
          <Item isActive>
            <ItemIcon><span className="material-symbols-outlined">visibility</span></ItemIcon>
            <ItemText>Check</ItemText>
          </Item>
          {/* <Item>
            <ItemIcon><span className="material-symbols-outlined">draw</span></ItemIcon>
            <ItemText>Edit</ItemText>
          </Item> */}
        </Bar>}
        subSidebar={<Bar>
            {/* <Item isActive>
              <ItemIcon><span className="material-symbols-outlined">image</span></ItemIcon>
              <ItemText>Single</ItemText>
            </Item>
            <Item>
              <ItemIcon><span className="material-symbols-outlined">gallery_thumbnail</span></ItemIcon>
              <ItemText>Thumb</ItemText>
            </Item>
            <Item>
              <ItemIcon><span className="material-symbols-outlined">grid_on</span></ItemIcon>
              <ItemText>Gallery</ItemText>
            </Item>                
            <HSeparator/>  */}
            <Item isActive={showZoom} onClick={() => {
                setShowZoom(!showZoom);
                setShowRulers(false);
            }}>
              <ItemIcon><span className="material-symbols-outlined">search</span></ItemIcon>
              <ItemText>Zoom</ItemText>
            </Item>
            <HSeparator/> 
            {/* <Item>
              <ItemIcon><span className="material-symbols-outlined">colorize</span></ItemIcon>
              <ItemText>Color pick</ItemText>
            </Item>
            <HSeparator/> */}
            <Item isActive={showRulers} onClick={() => {
              setShowRulers(!showRulers)
              setShowZoom(false);
            }}>
              <ItemIcon><span className="material-symbols-outlined">grid_4x4</span></ItemIcon>
              <ItemText>Grids</ItemText>
            </Item>
            {/*<Item>
              <ItemIcon><span className="material-symbols-outlined">table_eye</span></ItemIcon>
              <ItemText>Guidelines</ItemText>
            </Item>*/} 
            <HSeparator/>  
            <Item isActive={showTransparency} onClick={() => setShowTransparency(!showTransparency)}>
              <ItemIcon><span className="material-symbols-outlined">transition_fade</span></ItemIcon> 
              <ItemText>Transp.</ItemText>
            </Item>
            <HSeparator/>
            <Item isActive>
              <ItemPicker>
                <ColorPicker 
                  value={bgcolor} 
                  height={"26px"}
                  onChange={(color) => setBgcolor(color)} 
                  theme="dark"
                />
              </ItemPicker> 
              <ItemText>BG color</ItemText>
            </Item>
        </Bar>}
        content={
          <MacroContent>
            {allFilesPaths.length > 1 ?
              <Arrow
                prev
                onClick={() => onNav(
                  allFilesPaths.indexOf(filePath)-1 > -1 ?
                    allFilesPaths[allFilesPaths.indexOf(filePath)-1]
                  :
                    allFilesPaths[allFilesPaths.length - 1]
                )}
              >
                <span className="material-symbols-outlined">arrow_back_ios</span>
              </Arrow>
            : null}
            <RulersContent showRulers={showRulers}>
              
              <Content>                 
                              
                <ImageWrapper showZoom={showZoom} showTransparency={showTransparency} bgcolor={bgcolor}>
                  {showRulers ? 
                    <>
                      <HorizontalRuler onClick={() => addRuler()} max={dimensions ? dimensions.width : 0} />
                      <VerticalRuler onClick={() => addRuler()} max={dimensions ? dimensions.height : 0} />
                      {rulers.map((ruler, i) => 
                        ruler ? 
                          <Ruler 
                            key={i}
                            direction={ruler.from === "top" || ruler.from === "bottom" ? "horizontal" : "vertical"} 
                            from={ruler.from}
                            value={ruler.value}
                            max={dimensions ? (ruler.from === "top" || ruler.from === "bottom" ? dimensions.height : dimensions.width) : 0 }
                            onClick={() => addRuler(ruler, i)}
                          />
                        : null
                      )}
                    </>
                  : null} 
                  {showZoom ? 
                    <TransformWrapper 
                      // onZoomStart={() => setZooming(true)}
                      // onPanningStop={() => setZooming(false)}
                    >
                      <MinimapWrapper>
                        <MiniMap width={150} height={150}><Image src={downloadUrl} alt={"Preview "+filePath} /></MiniMap>
                      </MinimapWrapper>
                      <TransformComponent>
                        <Image src={downloadUrl} alt={"Preview "+filePath} /> 
                      </TransformComponent>
                    </TransformWrapper>
                  : 
                    <Image showRulers={showRulers} src={downloadUrl} alt={"Preview "+filePath} /> 
                  }
                </ImageWrapper>                  
                
              </Content>
            </RulersContent>
            {allFilesPaths.length > 1 ?
              <Arrow
                next
                onClick={() => onNav(
                  allFilesPaths.indexOf(filePath)+1 < allFilesPaths.length ?
                    allFilesPaths[allFilesPaths.indexOf(filePath)+1]
                  :
                    allFilesPaths[0]
                )}
              >
                <span className="material-symbols-outlined">arrow_forward_ios</span>
              </Arrow>
            : null}
          </MacroContent>
        }
        bottomSidebar={<Counter>{allFilesPaths.indexOf(filePath)+1}/{allFilesPaths.length}</Counter>}
            bottomBar={
              <BottomBar>
                <CurrentPath>
                  <CurrentPathIcon><span className="material-symbols-outlined">folder_open</span></CurrentPathIcon>
                  {filePath.split('/')[filePath.split('/').length - 2]}</CurrentPath>
                <VSeparator/>
                <FileName>
                  <OzButton size="small" noMinWidth variant="push-jet" onClick={() => {
                    navigator.clipboard.writeText(filePath.split('/')[filePath.split('/').length - 1]);
                  }}>
                    <ButtonIcon><span className="material-symbols-outlined">content_copy</span></ButtonIcon>
                  </OzButton>
                  {filePath.split('/')[filePath.split('/').length - 1]}
                </FileName>
                <DownloadButton>
                <OzButton size="small" noMinWidth variant="push-jet" onClick={() => {
                    getFiles([filePath])
                  }}>
                    <ButtonIcon><span className="material-symbols-outlined">download</span></ButtonIcon>
                  </OzButton>
                </DownloadButton>
                <VSeparator/>
                <FileInfo>{dimensions ? dimensions.width + " x " + dimensions.height : null}  </FileInfo>
              </BottomBar>
            }
            fnClose={() => removeOverlay("preview")}
        />
    )
}

export default OverlayPreview;
